import { useEffect, useState } from "react";
import serverClient from "../api/serverClient";
import { PrayerWallEntry } from "../types";
import { Geolocation } from "./useGeolocation";

const usePrayerWallEntries = (search: string, geolocation?: Geolocation) => {
  const [entries, setEntries] = useState<PrayerWallEntry[]>([]);
  const [selectedEntry, setSelectedEntry] = useState<PrayerWallEntry | null>(null);
  const [loading, setLoading] = useState(true);
  const [allEntriesLoaded, setAllEntriesLoaded] = useState(false);

  useEffect(() => {
    setEntries([]);
    fetchEntries([]);
  }, [search]);

  const fetchEntries = async (entries: PrayerWallEntry[]) => {
    setLoading(true);
    let result =
      (await serverClient.retrieveRecentEntries(search.replace(/'/g, 'A'), entries.length)) ?? [];
    if (result.length === 0) {
      setAllEntriesLoaded(true);
    }
    const uniqueEntries = removeDuplicateEntries([...entries, ...result], 'uuid');
    setEntries(uniqueEntries);
    setLoading(false);
  };

  const removeDuplicateEntries = (entries: PrayerWallEntry[], key: string) => {
    const check = new Set();
    //@ts-ignore
    return entries.filter((entry: PrayerWallEntry) => !check.has(entry[key]) && check.add(entry[key]));
  };

  const incrementPrayedForCount = async (entry: PrayerWallEntry) => {
    let coords = geolocation
      ? [geolocation.latitude, geolocation.longitude]
      : undefined;
    serverClient.incrementPrayedForCount(entry.uuid, coords);
    let index = entries.findIndex((e) => e.uuid === entry.uuid);
    entries[index].prayed_for_count++;
    entries[index].has_prayed_for = true;
    setEntries([...entries]);
  };

  const setCurrentSelectedEntry = (entry: PrayerWallEntry | null) => {
    if (entry?.uuid !== selectedEntry?.uuid) {
      setSelectedEntry(entry);
    }
  };

  return {
    entries,
    selectedEntry,
    setCurrentSelectedEntry,
    loading,
    fetchNextEntries: fetchEntries,
    incrementPrayedForCount,
    allEntriesLoaded,
  };
};

export default usePrayerWallEntries;
