import styled from "styled-components";
import checkmarkIcon from "../../media/checkmark-icon.svg";

export const FormWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  /* background-color: ${({ theme }) => theme.colors?.["ui-light"]}; */
  border-radius: ${({ theme }) =>
    theme.utilities?.borders?.["border-radius-lg"]};
  padding: ${({ theme }) => theme.utilities?.spacing?.["space-md"]}
    calc(${({ theme }) => theme.utilities?.spacing?.["space-sm"]} * 1.5);
  padding-right: calc(${({ theme }) => theme.utilities?.spacing?.["space-md"]});
  gap: ${({ theme }) => theme.utilities?.spacing?.["space-sm"]};

  .rdcomponent {
    margin-bottom: 0.5rem;
  }

  @media (max-width: 768px) {
    h3 {
      text-align: center;
    }
  }
`;

export const FormItemWrapper = styled.div`
  display: flex;
  flex-direction: column;

  > p {
    color: #000;
    font-weight: 600;
  }
`;

export const FormItemLabel = styled.p`
  color: #000;
  font-weight: 600;
`;

export const FormInput = styled.input`
  padding: ${({ theme }) => theme.utilities?.spacing?.["space-xs"]};
  border: 1px solid #D8D8D8;
  border-radius: ${({ theme }) =>
    theme.utilities?.borders?.["border-radius-md"]};
  outline: none;
  font-size: 0.9rem;

  ::placeholder {
    color: ${({ theme }) => theme.colors?.["text-light"]};
  }

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

export const FormInputInfo = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 0.25rem;

  > img {
    width: 0.65rem;
    margin-right: 0.25rem;
  }
  > p {
    width: 100%;
    color: #000 !important;
    font-size: 0.6rem;
    font-weight: 250 !important;
    padding: 0;
    span {
      font-weight: 600 !important;
    }
  }

  @media (max-width: 768px) {
    > img {
      width: 0.75rem;
    }
    > p {
      font-size: 0.675rem;
    }
  }
`;

export const FormTextArea = styled.span`
  padding: ${({ theme }) => theme.utilities?.spacing?.["space-xs"]};
  /* margin-bottom: ${({ theme }) => theme.utilities?.spacing?.["space-md"]}; */
  border: 1px solid #D8D8D8;
  border-radius: ${({ theme }) =>
    theme.utilities?.borders?.["border-radius-md"]};
  background-color: #fff;
  color: #000;
  outline: none;
  resize: none;
  min-height: 8rem;
  height: fit-content;
  cursor: text;

  &[placeholder]:empty:before {
    content: attr(placeholder);
    color: ${({ theme }) => theme.colors?.["text-light"]};
  }
`;

export const FormDropdown = styled.select`
  padding: ${({ theme }) => theme.utilities?.spacing?.["space-xs"]};
  border: 1px solid #D8D8D8;
  border-radius: ${({ theme }) =>
    theme.utilities?.borders?.["border-radius-md"]};
  color: #000;
  background-color: #fff;
  outline: none;
  font-size: 0.9rem;
  cursor: pointer;
  font-family: inherit;

  &:hover {
    opacity: 0.8;
  }
`;

export const FormLabel = styled.label`
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.colors?.["text-light"]};
  font-size: 0.9rem;
`;

export const FormCheckbox = styled.input`
  width: 19px;
  height: 19px;
  appearance: none;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: ${({ theme }) =>
    theme.utilities?.borders?.["border-radius-sm"]};
  border: 1px solid #D8D8D8;
  background-color: transparent !important;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.08);
  &:hover {
    cursor: pointer;
  }

  &::after {
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    content: url(${checkmarkIcon});
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) scale(1);
    filter: invert(59%) sepia(65%) saturate(452%) hue-rotate(178deg) brightness(93%) contrast(102%);   // TRANSFORM TO COLOR HEX 88D0FF.
    opacity: 0;
    font-size: 12px;
  }
  &:checked::before,
  &:checked::after {
    opacity: 1;
  }

  @media (max-width: 768px) {
    width: 25px;
    height: 25px;
  }
`;

export const FormCheckBoxWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.utilities?.spacing?.["space-sm"]};
  color: #000;
  font-size: 0.9rem;
  font-weight: 600;

  @media (max-width: 768px) {
    font-size: 0.85rem;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  margin-top: ${({ theme }) => theme.utilities?.spacing?.["space-sm"]};
  flex-direction: flex-start;

  button {
    font-size: 0.85rem;
    padding: 0.75rem ${({ theme }) => theme.utilities?.spacing?.["space-sm"]};
    background-color: #88d0ff;

    @media (max-width: 768px) {
      width: 100%;
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const FormInvalidWarning = styled.p`
  color: #b50000 !important;
  font-size: 0.75rem;
  font-weight: bold;
  padding: 0;
  width: 80%;
`;

export const FormApprovalDisclaimer = styled.p`
  color: #000;
  font-size: 0.75rem;
  margin-right: calc(-${({ theme }) => theme.utilities?.spacing?.["space-md"]} * 1.5);
  font-style: italic;
  width: 100%;
`;

export const IconRadioButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

export const RadioOption = styled.div`
  display: flex;
  flex-direction: column;
  gap: 7px;
  border: solid 1px #d9d9d9;
  padding: 0.5rem 1rem;
  border-radius: 12px;
  align-items: center;
  text-align: center;
  background: #fcfeff;
  width: 100px;
  /* height: 126px; */
  font-size: 0.9rem;
  font-weight: bold;
  color: black;
  img {
    width: 50px;
    height: 50px;
  }
`;

export const RadioButton = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0.125rem 0;
  align-items: left;
  text-align: left;
  width: 100%;
  color: black;
  gap: ${({ theme }) => theme.utilities?.spacing?.["space-xs"]};
  input[type='radio'] {
    width: unset !important;
    height: unset !important;
  }
  p {
    font-size: 0.875rem !important;
    @media (max-width: 768px) {
      font-size: 0.85rem;
    }
  }
`;

export const FormInputRow = styled.div`
  display: flex;
  /* flex-wrap: wrap; */
  width: 100%;
  margin-bottom: -${({ theme }) => theme.utilities?.spacing?.["space-sm"]};
  gap: ${({ theme }) => theme.utilities?.spacing?.["space-sm"]};
  > div {
    width: 100%;
  }
`;
