import { FormInputConfig } from "../formConfig";

export const formItems: FormInputConfig[] = [
  {
    accessorKey: "preferred_contact_method",
    placeholder: "Please choose your preferred method of contact",
    name: "Please choose your preferred method of contact",
    required: true,
    requiresValidation: false,
    type: "radiobox",
    options: [
      // ["whatsapp", "Whatsapp"],
      ["sms", "SMS"],
      ["email", "Email"],
    ],
  },
  {
    accessorKey: "gender",
    placeholder: "Gender",
    name: "Gender",
    required: true,
    requiresValidation: false,
    type: "dropdown",
    options: [
      ["male", "Male"],
      ["female", "Female"],
      ["preferNotToSay", "Prefer not to say"],
    ],
  },
];

export const CONNECT_THANK_YOU_MESSAGE =
  "Our team will be in touch shortly to connect you to a local church. In the meantime, why not pray for some other requests? Also, while you're waiting, check out the mobile version of Prayer Map and more in <a href='https://mynota.io/'>My Nota app</a>.";
