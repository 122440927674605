import { FiltersInputWrapper, FiltersLabel, FiltersToggleButton, FiltersToggleButtonWrapper } from "./styles";

export type MapView = "us" | "world";
export type AnsweredStatus = "all" | "unanswered" | "answered";

export type MapSidebarFiltersProps = {
    mapView: MapView;
    answeredStatus: AnsweredStatus;
    onMapViewChange: (mapView: MapView) => void;
    onAnsweredStatusChange: (answeredStatus: AnsweredStatus) => void;
    isNota?: boolean;
}

const MapSidebarFilters: React.FC<MapSidebarFiltersProps> = ({
    mapView,
    answeredStatus,
    onMapViewChange,
    onAnsweredStatusChange,
    isNota
}) => {
    return (
        <div>
            <FiltersInputWrapper>
                <FiltersLabel className={`${isNota && "isNota"}`}>Map View</FiltersLabel>
                <FiltersToggleButtonWrapper>
                    <FiltersToggleButton className={`btn ${mapView === 'us' && "selected"}`} onClick={() => onMapViewChange('us')}>
                        United States
                    </FiltersToggleButton>
                    <FiltersToggleButton className={`btn ${mapView === 'world' && "selected"}`} onClick={() => onMapViewChange('world')}>
                        World View
                    </FiltersToggleButton>
                </FiltersToggleButtonWrapper>

                <FiltersLabel className={`${isNota && "isNota"}`}>Prayer Visibility</FiltersLabel>
                <FiltersToggleButtonWrapper>
                    <FiltersToggleButton className={`btn ${answeredStatus === 'all' && "selected"}`} onClick={() => onAnsweredStatusChange('all')}>
                        All
                    </FiltersToggleButton>
                    <FiltersToggleButton className={`btn ${answeredStatus === 'unanswered' && "selected"}`} onClick={() => onAnsweredStatusChange('unanswered')}>
                        Unanswered
                    </FiltersToggleButton>
                    <FiltersToggleButton className={`btn ${answeredStatus === 'answered' && "selected"}`} onClick={() => onAnsweredStatusChange('answered')}>
                        Answered
                    </FiltersToggleButton>
                </FiltersToggleButtonWrapper>
            </FiltersInputWrapper>
        </div>
    )
}

export default MapSidebarFilters;
