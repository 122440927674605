import styled, { keyframes } from "styled-components";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;

  margin-bottom: ${({ theme }) => theme.utilities?.spacing?.["space-md"]} 0;
`;

const animation = keyframes`
  0% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(-15px);
  }
  50% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(0);
  }
`;

const Dot = styled.div`
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors?.["primary-light"]};
  animation: ${animation} 1.5s ease 0s infinite;
  animation-delay: 0.2s;

  &:first-child {
    animation-delay: 0s;
  }
  &:last-child {
    animation-delay: 0.3s;
  }
`;

const Loader = () => (
  <Wrapper className="loader-wrapper">
    <Dot />
    <Dot />
    <Dot />
  </Wrapper>
);

export default Loader;
