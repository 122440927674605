import styled from "styled-components";

export const MapWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  background-color: #060b13;

  .metric {
    position: absolute;
    bottom: ${({ theme }) => theme?.utilities.spacing["space-lg"]};
    left: ${({ theme }) => theme?.utilities.spacing["space-lg"]};
    min-width: 350px;
    width: unset;
    background-color: #00000090;
    margin-bottom: 0;
    //background-color: #E5EDFF25;

    * {
      color: white;
      opacity: 1;
    }
  }

  .profile-tooltip {
    position: absolute;
    z-index: 1;
    pointer-events: none;
    white-space: nowrap;
    filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.5));
    transform: translate(25px, -50%);
    background-color: #f6f6f6;
    border-radius: ${({ theme }) =>
      theme?.utilities.borders["border-radius-sm"]};
    padding: ${({ theme }) => theme?.utilities.spacing["space-xs"]}
      ${({ theme }) => theme?.utilities.spacing["space-sm"]};
  }
`;

export const MapLoading = styled.div`
  width: 100%;
  height: 100%;
  z-index: 5;
  position: absolute;
  top: 0;
  background-color: #00000080;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const MapLoadingSmall = styled.div`
  position: absolute;
  right: ${({ theme }) => theme?.utilities.spacing["space-md"]};
  bottom: ${({ theme }) => theme?.utilities.spacing["space-md"]};
  z-index: 2;

  .spinner-container {
    transform: scale(0.65);
  }
`;

export const MapTitle = styled.div`
  display: none;
  position: absolute;
  top: ${({ theme }) => theme?.utilities.spacing["space-lg"]};
  left: ${({ theme }) => theme?.utilities.spacing["space-lg"]};
  padding: ${({ theme }) => theme?.utilities.spacing["space-sm"]}
    ${({ theme }) => theme?.utilities.spacing["space-md"]};
  border-radius: ${({ theme }) => theme?.utilities.borders["border-radius-lg"]};
  background-color: ${({ theme }) => theme?.colors["ui-gray-lighter"]};
  box-shadow: 0px 2px 4px rgba(22, 38, 77, 0.5);
  flex-direction: column;
  z-index: 4;

  .btn {
    font-size: 0.75rem;
    margin-top: ${({ theme }) => theme?.utilities.spacing["space-xs"]};
  }
`;
