import React from "react";
import { useEffect, useState } from "react";
import {
  EmptyText,
  PrayerWallWrapper,
  PrayerWallContentWrapper,
  PrayerWallLeftWrapper,
  PrayerWallRightWrapper,
  PrayerWallAsideContent,
  PWSubmitRequest,
  PWViewRequests,
  HeaderForm,
  ThankYouCard,
  ThankYouHeader,
  ThankYouText,
  CloseThankYouCard,
  PrayerWallContentWrapperNota,
} from "./prayerWall.styles";
import PrayerWallAppBar from "./prayerWallAppBar";
import RequestForm from "../../components/requestForm";
import usePrayerWallEntries from "../../hooks/usePrayerWallEntries";
import Loader from "../../components/loader";
import { useInView } from "react-intersection-observer";
import SearchBar from "../../components/searchBar";
import useGeolocation from "../../hooks/useGeolocation";
import {
  Redirect,
  Route,
  Switch,
  NavLink,
  useHistory,
  useLocation,
} from "react-router-dom";
import ConnectChurchForm from "../../components/requestForm/connectChurchForm/connectChurchForm";
import PrayerRequestDetailed from "../../components/prayerRequest/detailed/detailed";
import PrayerRequestsWrapper from "../../components/prayerRequest/wrapper/wrapper";
import { usePrayerRequestContext } from "../../hooks/context/prayerRequest/prayerRequestContext";
import { PrayerWallEntry } from "../../types";

import circleX from "../../media/circle-x.svg";
import RequestPopout from "../../components/prayerRequest/popout/popout";
import FullMap from "../../components/fullMap";
import { FullMapWrapper, MapContent } from "../../components/fullMap/styles";
import ArcMap from "../../components/arcMap/arcMap";

const PrayerWall: React.FC<any | null> = (props) => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const isNotaApp = params.get('nota') === 'true';

  const [search, setSearch] = useState<string>("");
  const [fullscreen, toggleFullscreen] = useState<boolean>(false);
  const [leaving, setLeaving] = useState<boolean>(false);

  const { ref, inView } = useInView();
  const geolocation = useGeolocation();
  const history = useHistory();
  const searchParams = location.search;
  const { thankYouMessage, setThankYouMessage } = usePrayerRequestContext();
  const {
    entries,
    selectedEntry,
    setCurrentSelectedEntry,
    loading,
    incrementPrayedForCount,
    fetchNextEntries,
    allEntriesLoaded,
  } = usePrayerWallEntries(search, geolocation);

  useEffect(() => {
    // console.log(props.match.params.influencer);
  }, []);
  useEffect(() => {
    if (isNotaApp) {
      toggleFullscreen(isNotaApp)
    }
  }, [isNotaApp]);

  useEffect(() => {
    if (!allEntriesLoaded && entries.length > 0 && inView && !loading) {
      fetchNextEntries(entries);
    }
  }, [allEntriesLoaded, entries, inView, loading]);

  const scrollToPrayerRequest = (uuid: string) => {
    prayerRequestRefs[uuid].current.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest"
    });
  }

  const onSearch = (search: string) => {
    setSearch(search);
  };

  const handleSelectEntry = (selectedEntry: PrayerWallEntry) => () => {
    setCurrentSelectedEntry(selectedEntry);
    scrollToPrayerRequest(selectedEntry.uuid);
    // history.push(`/requests/${selectedEntry.uuid}`)
  };

  const handleSelectEntryFromMap = (selectedEntry: PrayerWallEntry) => {
    setCurrentSelectedEntry(selectedEntry);
    setSearch(selectedEntry.uuid);
    history.push(`/requests${searchParams}`);
  };

  const handleResetSelection = () => {
    setCurrentSelectedEntry(null);
    history.push(`/requests${searchParams}`);
  };

  const handleLeave = () => {
    setLeaving(true);
    setSearch("");
    setTimeout(() => {
      setLeaving(false);
      handleResetSelection();
    }, 350);
  };

  const prayerRequestRefs = entries.reduce((acc: any, value: any) => {
    acc[value.uuid] = React.createRef();
    return acc;
  }, {});
  const showFullscreenRequestPopout = selectedEntry && fullscreen;

  // const goToRequestsAfterSelection = selectedEntry && !fullscreen;

  if (isNotaApp)
    return (
      <PrayerWallWrapper id="prayer-wall">
        <PrayerWallContentWrapperNota>
          <FullMapWrapper className="fullmap-wrapper">
            <MapContent>
              <ArcMap
                title=""
                loading={false}
                filters={{ foo: [] }}
                selectedEntry={selectedEntry}
                handleSelectEntry={handleSelectEntryFromMap}
                isNota={true}
                {...{ handleLeave }}
              />
            </MapContent>
          </FullMapWrapper>

          {showFullscreenRequestPopout && (
            <RequestPopout
              {...{ selectedEntry }}
              {...{ incrementPrayedForCount }}
              {...{ leaving }}
              {...{ handleLeave }}
              isNota={isNotaApp}
            />
          )}
        </PrayerWallContentWrapperNota>
      </PrayerWallWrapper>
    );

  return (
    <PrayerWallWrapper id="prayer-wall">
      <PrayerWallAppBar {...{ selectedEntry }} />
      <PrayerWallContentWrapper>
        <PrayerWallLeftWrapper className="left-wrapper">
          <FullMap
            onReset={() => handleResetSelection()}
            {...{ onSearch }}
            {...{ selectedEntry }}
            {...{ handleSelectEntryFromMap }}
            {...{ fullscreen }}
            {...{ toggleFullscreen }}
              isNota={isNotaApp}
            {...{ handleLeave }}
          />
        </PrayerWallLeftWrapper>
        <PrayerWallRightWrapper className="right-wrapper">
          <div className="tab-manager">{/* tablet and smaller */}
            <NavLink
              to={{ pathname: "/submit-request", search: searchParams }}
              activeClassName="active"
            >
              <div>Submit Request</div>
            </NavLink>
            <NavLink
              to={
                selectedEntry
                  ? `/requests/${selectedEntry.uuid}${searchParams}`
                  : `/requests${searchParams}`
              }
            >
              <div>View Requests</div>
            </NavLink>
          </div>
          <Switch>
            <Route exact path="/submit-request">
              <PWSubmitRequest>
                <HeaderForm>
                  <RequestForm geolocation={geolocation} />
                </HeaderForm>
              </PWSubmitRequest>
            </Route>
            <Route exact path="/requests/:uuid/answer">
              <PrayerRequestDetailed
                {...{ entries }}
                {...{ selectedEntry }}
                {...{ setSearch }}
                {...{ setCurrentSelectedEntry }}
              />
            </Route>
            <Route exact path="/requests/:uuid">
              <PWViewRequests>
                <PrayerWallAsideContent>
                  <SearchBar onSearch={onSearch} onReset={() => handleResetSelection()} {...{ selectedEntry }} />
                  <PrayerRequestsWrapper
                    {...{ entries }}
                    {...{ selectedEntry }}
                    {...{ prayerRequestRefs }}
                    {...{ incrementPrayedForCount }}
                    {...{ setCurrentSelectedEntry }}
                    {...{ handleSelectEntry }}
                    {...{ setSearch }}
                  />
                  {entries.length === 0 && !loading && (
                    <EmptyText>No prayer requests found.</EmptyText>
                  )}
                  {loading && <Loader />}
                </PrayerWallAsideContent>
              </PWViewRequests>
            </Route>
            <Route exact path="/requests">
              <PWViewRequests>
                <PrayerWallAsideContent>
                  {thankYouMessage && (
                    <ThankYouCard>
                      <ThankYouHeader>
                        <h3>Thank You</h3>
                        <CloseThankYouCard onClick={() => setThankYouMessage("")}>
                          <img src={circleX} alt="close icon" />
                        </CloseThankYouCard>
                      </ThankYouHeader>
                      <ThankYouText dangerouslySetInnerHTML={{ __html: thankYouMessage }}></ThankYouText>
                    </ThankYouCard>
                  )}
                  <SearchBar onSearch={onSearch} onReset={() => handleResetSelection()} {...{ selectedEntry }} />
                  <PrayerRequestsWrapper
                    {...{ entries }}
                    {...{ selectedEntry }}
                    {...{ prayerRequestRefs }}
                    {...{ incrementPrayedForCount }}
                    {...{ handleSelectEntry }}
                  />
                  <div ref={ref} />
                  {entries.length === 0 && !loading && (
                    <EmptyText>No prayer requests found.</EmptyText>
                  )}
                  {loading && <Loader />}
                </PrayerWallAsideContent>
              </PWViewRequests>
            </Route>
            <Route exact path="/submit-request/register-seeker">
              <ConnectChurchForm />
            </Route>
            <Route exact path="/">
              <Redirect push to={`/submit-request${searchParams}`} />
            </Route>
          </Switch>
        </PrayerWallRightWrapper>

        {showFullscreenRequestPopout && (
          <RequestPopout
            {...{ selectedEntry }}
            {...{ incrementPrayedForCount }}
            {...{ leaving }}
            {...{ handleLeave }}
              isNota={isNotaApp}
          />
        )}
      </PrayerWallContentWrapper>
    </PrayerWallWrapper>
  );
};

export default PrayerWall;
