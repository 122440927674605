import styled, { keyframes } from "styled-components";
import { RequestActionRow } from "../prayerRequest.styles";

export const ModalEnter = keyframes`
  0% {
    transform: translateX(-100vh);
  }
  100% {
    transform: translateX(0);
  }
`;

export const ModalLeave = keyframes`
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100vh);
  }
`;

export const PrayerWallProfile = styled.div`
    pointer-events: auto;
    position: absolute;
    background-color: #FFF;
    border-radius: ${({ theme }) => theme?.utilities.borders["border-radius-lg"]};
    box-shadow: 0px 2px 6px #000000;
    display: grid;
    grid-template-columns: 2fr 3fr;
    overflow: auto;

    &.show-testimony {
        grid-template-columns: 1.49fr 3fr;
    }
    z-index: 100;
    display: flex;
    flex-direction: column;
    width: 600px;
    left: calc(30% - (650px / 2));
    bottom: 5%;

    &.notaSideBar {
      position: relative;
      margin: 10px 0;
      width: 100% !important;
      grid-template-columns: 1fr;
      place-items: center;
      left: 0;
    }

    &:not(.leaving) {
      &:not(.notaSideBar) {
        animation: 0.35s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s 1 ${ModalEnter};
      }
    }

    &.leaving {
        transform: translateY(-100vh);
        animation: 0.35s cubic-bezier(0.68, 0, 0.83, -0.275) 0s 1 ${ModalLeave};
    }

    ${RequestActionRow} {
        padding: 1rem 2rem;
        @media screen and (max-width: 768px) {
            * {
            font-size: 0.5rem;
            }
        }
    }

    @media screen and (max-width: 768px) {
        max-width: 100%;
        width: 550px;
        height: auto;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        * {
            font-size: 0.7rem;
        }
        &.notaApp {
          height: fit-content;
          top:unset;
          &:not(.notaSideBar) {
            width: 90%;
          }
        }

        &.notaSideBar {
          transform: translateX(0);
        }
    }
`;

export const ProfileInfo = styled.div`
  width: 100%;
  padding: 4rem 4rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  &.notaApp {
    gap: .2rem;
  }

  @media screen and (max-width: 768px) {
    padding: 1rem;
  }
  &.notaApp {
    align-items: flex-start;
    .prayed-for-created-at {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between !important;
      width: 100%;
      * {
        width: fit-content;
        padding: 0;
        margin: 0;
        font-size: .85rem;
      }
    }
    h1 {
      font-size: initial;
      color: black;
    }
    
  }
`;

export const CreatedAt = styled.p`
  width: 100%;
  text-align: right;
  opacity: 0.8;
  font-size: 0.85rem;
`;

export const ProfileClose = styled.div`
  position: absolute;
  top: ${({ theme }) => theme?.utilities.spacing["space-sm"]};
  right: ${({ theme }) => theme?.utilities.spacing["space-sm"]};
  margin-right: 10px;
  font-size: 1rem;
  font-weight: 700;
  cursor: pointer;
  &.notaApp {
    color: black;
  }

  &:hover {
    text-decoration: underline;
  }
`;

export const ProfileHeader = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  height: 30px;
`;

export const ProfileHeaderText = styled.div`
  background-color: ${({ theme }) => theme?.colors["primary-dark"]};
  color: white;
  text-transform: uppercase;
  padding: 0 5rem;
  font-size: 0.75rem;
  letter-spacing: 1px;
  line-height: 30px;
  position: relative;

  &::before {
    position: absolute;
    top: 0px;
    left: -1px;
    content: "";
    border-bottom: 15px solid ${({ theme }) => theme?.colors["ui-gray-lighter"]};
    border-left: 15px solid ${({ theme }) => theme?.colors["ui-gray-lighter"]};
    border-top: 15px solid transparent;
    border-right: 15px solid transparent;
  }
  &::after {
    position: absolute;
    top: 0px;
    right: -1px;
    content: "";
    border-bottom: 15px solid ${({ theme }) => theme?.colors["ui-gray-lighter"]};
    border-right: 15px solid ${({ theme }) => theme?.colors["ui-gray-lighter"]};
    border-top: 15px solid transparent;
    border-left: 15px solid transparent;
  }
`;

export const CopyUrlButton = styled.button`
  font-size: 1rem !important;
  padding: 0 !important;
  background: transparent !important;
  border: none;
  color: #1F356E;
  margin-left: 7px;
  margin-bottom: 5px;
  cursor: pointer;

  span {
    font-size: 0.7rem;
  }
`;
