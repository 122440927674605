import { useEffect, useState } from "react";

const geolocationURL = process.env.REACT_APP_GEOLOCATION_URL;

export type Geolocation = {
  city: string;
  country: string;
  countryCode: string;
  latitude: number;
  longitude: number;
  region: string;
  regionCode: string;
};

export type GeolocationResponse = {
  success: boolean;
  geolocation: Geolocation;
};

const useGeolocation = () => {
  const [geolocation, setGeolocation] = useState<Geolocation>();

  useEffect(() => {
    getGeolocation();
  }, []);

  const getGeolocation = async () => {
    let response = await fetch(geolocationURL!);
    let result = (await response.json()) as unknown as GeolocationResponse;
    if (result.success) {
      setGeolocation(result.geolocation);
    }
  };

  return geolocation;
};

export default useGeolocation;
