import { useEffect, useState } from "react";
import serverClient from "../../../api/serverClient";
import {
  DownloadButton,
  EncouragementListTitle,
  EncouragementListTotal,
  EncouragementListWrapper,
} from "./encouragementList.styles";
import Loader from "../../../components/loader";

type EncouragementListProps = {
  refresh: boolean;
};

const EncouragementList: React.FC<EncouragementListProps> = ({ refresh }) => {
  const [loading, setLoading] = useState(true);
  const [encouragementEmails, setEncouragementEmails] = useState<any[]>([]);

  const getEncouragementEmails = async () => {
    setLoading(true);
    let result = await serverClient.getEncouragementEmails();
    setLoading(false);
    setEncouragementEmails(result);
  };

  useEffect(() => {
    getEncouragementEmails();
  }, []);

  useEffect(() => {
    if (refresh) {
      setEncouragementEmails([]);
      getEncouragementEmails();
    }
    //eslint-disable-next-line
  }, [refresh]);

  return (
    <EncouragementListWrapper>
      <EncouragementListTitle>
        Total Weekly Encouragement Subscribers:{" "}
      </EncouragementListTitle>
      {loading ? (
        <Loader />
      ) : (
        <EncouragementListTotal>
          {encouragementEmails?.length ?? 0}
        </EncouragementListTotal>
      )}
      <DownloadButton
        data={encouragementEmails.map(object => object.email).join(",\n")}
        filename="weekly-encouragement-emails.csv"
        enclosingCharacter=""
      >
        Download CSV
      </DownloadButton>
    </EncouragementListWrapper>
  );
};

export default EncouragementList;
