import styled from "styled-components";

export const Request = styled.div`
  width: 100%;
  padding: calc(${({ theme }) => theme.utilities?.spacing?.["space-sm"]} * 1.5)
    calc(${({ theme }) => theme.utilities?.spacing?.["space-sm"]} * 1.5);
  border-radius: ${({ theme }) =>
    theme.utilities?.borders?.["border-radius-md"]};
  color: #000;

  display: flex;
  flex-direction: column;
  position: relative;
  gap: ${({ theme }) => theme.utilities?.spacing?.["space-xs"]};
  transition: ease 0.2s;
  cursor: pointer;

  .map-pointer {
    display: flex;
    position: absolute;
    z-index: 1000;
    width: 0px;
    height: 0px;

    background: transparent;
    border-top: 19px solid transparent;
    border-bottom: 19px solid transparent;
    border-left: 19px solid transparent;
    border-right: 19px solid #fff;

    & div {
      position: absolute;
      top: -18px;
      left: 1px;
      width: 0;
      height: 0;
      border-top: 18px solid transparent;
      border-bottom: 18px solid transparent;
      border-right: 18px solid #88D0FF;

      @media (max-width: 810px) {
        border: 0;
      }
    }

    top: 50%;
    margin: -1.5rem -2.75rem;
    transform: translateX(-50%);

    @media (max-width: 810px) {
      border: 0;
    }
  }

  .social-button {
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
    transition: 0.2s;

    &:hover {
      filter: brightness(0.8);
    }
  }

  &:hover {
    border: 1px solid #1F356E;
  }

  &.selected {
    border: 1px solid #1F356E;
    background-color: #E3E5EB;
    box-shadow: 0px 5px 20px 0px #1F356E;
    .map-pointer {
      display: flex;
    }
  }
  &.not-selected {
    border: 1px solid #DCDCDC;
    background-color: ${({ theme }) => theme.colors?.["ui-light"]};
    .map-pointer {
      display: none;
    }
  }
  &.answered {
    background-color: #88D0FF;
  }

  @media (max-width: 768px) {
    width: 100%;
    align-items: left;
    text-align: left;

    .social-button {
      &.facebook {
        grid-area: facebook;
      }
      &.twitter {
        grid-area: twitter;
      }
    }
  }
`;

export const RequestSelectableArea = styled.div`
  cursor: pointer;
`;

export const RequestHeader = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: ${({ theme }) => theme.utilities?.spacing?.["space-sm"]};

  h4 {
    float: left;
    font-size: 1rem;

    @media (min-width: 1440px) {
      font-size: 1.25rem;
    }
  }

  small {
    color: #1F356E;
    text-decoration: underline;
  }

  div {
    display: flex;
    flex-direction: column;
  }

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const RequestDate = styled.p`
  font-size: 0.75rem;
  align-self: left;
  margin-left: auto;

  @media (min-width: 1440px) {
    font-size: 0.9rem;
  }

  @media (max-width: 768px) {
    margin-left: 0;
    align-self: left;
    margin-bottom: ${({ theme }) => theme.utilities?.spacing?.["space-sm"]};
  }
  @media (min-width: 1024px) {
    align-self: center;
  }
`;

export const RequestText = styled.p`
  margin: ${({ theme }) => theme.utilities?.spacing?.["space-xs"]} 0;
  font-weight: light;
  font-size: 0.9rem;
  &.notaApp {
    color: black;
  }

  @media (min-width: 1440px) {
    font-size: 1rem;
  }

  @media (max-width: 768px) {
    text-align: left;
  }
`;

export const RequestTextExpand = styled.span`
  margin-left: 15px;
  opacity: 0.8;
  color: #1F356E;
  text-decoration: underline;
`;

export const RequestActionRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: ${({ theme }) => theme.utilities?.spacing?.["space-sm"]};
  padding: ${({ theme }) => theme.utilities?.spacing?.["space-sm"]}
    ${({ theme }) => theme.utilities?.spacing?.["space-xs"]} 0;
  margin-top: ${({ theme }) => theme.utilities?.spacing?.["space-xs"]};
  border-top: 1px solid ${({ theme }) => theme.colors?.["text-default"]}25;
  &.notaApp {
    justify-content: center !important;
    gap: ${({ theme }) => theme.utilities?.spacing?.["space-md"]};
  }

  & div {
    display: flex;
    width: 60%;
    align-items: center;
    flex-direction: column;
    &.notaApp {
      align-items: center !important;
      &.share-buttons-nota-container * {
        gap: ${({ theme }) => theme.utilities?.spacing?.["space-xs"]};
      }
      &.share-buttons-nota-container{
        width: fit-content;
      }
    }

    & div {
      min-width: 100%;
      display: flex;
      flex-direction: row;
      margin-right: ${({ theme }) => theme.utilities?.spacing?.["space-sm"]};
      &.notaApp.share-buttons-nota {
        min-width: unset;
        transform: translateY(.4em);
        * {
          font-size: 1rem !important;
        }
      }

      & p {
        font-size: 0.75rem;

        @media (min-width: 1578px) {
          font-size: 0.9rem;
        }
      }
    }
  }
  & div:last-child {
    display: flex;
    width: 40%;
    &.notaApp.prayer-button-nota {
      margin: 0 !important;
      width: fit-content;
      & button {
        width: 150px;
      }
    }

    & button {
      font-size: 0.75rem;
      background-color: #88D0FF;
      padding: 0.75rem ${({ theme }) => theme.utilities?.spacing?.["space-sm"]};
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    &.notaApp {
      flex-direction: row-reverse !important;
      padding: 20px;
    }

    & div {
      width: 100%;
    }
    & div:last-child {
      width: 100%;

      & button {
        //width: 80%;
      }
    }
  }
`;

export const RequestPrayedForCount = styled.p`
  display: block;
  width: 100%;
  font-weight: light;
  letter-spacing: 0.05em;
  font-size: 0.75rem;
  color: #000;
  margin-right: ${({ theme }) => theme.utilities?.spacing?.["space-sm"]};

  @media (max-width: 768px) {
    font-size: 0.875rem;
    grid-area: count;
    width: 100%;
  }
  @media (min-width: 1440px) {
    font-size: 1rem;
  }
`;

export const RequestThankYou = styled.p`
  font-size: 1rem;
  padding: 0.75rem ${({ theme }) => theme.utilities?.spacing?.["space-sm"]};
  color: #000;
  font-weight: bold;

  @media (max-width: 768px) {
    grid-area: pray;
  }
`;

export const RequestAnswered = styled.p`
  font-size: 1rem;
  padding: 0.75rem ${({ theme }) => theme.utilities?.spacing?.["space-sm"]};
  color: #1F356E;
  font-weight: bold;
`;

export const CopyUrlButton = styled.button`
  font-size: 1rem !important;
  padding: 0 !important;
  background: transparent !important;
  border: none;
  color: #1F356E;
  margin-top: 2px;
  margin-left: 7px;
  cursor: pointer;

  span {
    font-size: 0.7rem;
  }
`;
