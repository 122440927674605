import React, { useState } from "react";
import { FacebookShareButton, TwitterShareButton } from "react-share";
import {
  faPrayingHands,
  faCheck,
  faCopy,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PrayerWallEntry } from "../../types";
import Button from "../button";
import {
  Request,
  RequestSelectableArea,
  RequestHeader,
  RequestActionRow,
  RequestDate,
  RequestPrayedForCount,
  RequestText,
  RequestTextExpand,
  RequestThankYou,
  RequestAnswered,
  CopyUrlButton,
} from "./prayerRequest.styles";
import classNames from "classnames";

import FbIcon from "../../media/facebook.svg";
import XIcon from "../../media/x.svg";

type PrayerRequestProps = {
  entry: PrayerWallEntry;
  incrementPrayedForCount: (entry: PrayerWallEntry) => any;
  handleSelectEntry: (entry: PrayerWallEntry) => () => any;
  handleEntryStyle: string;
};

const trimRequestText = (text: string, length = 150) => {
  if (text.length < length) return text;

  return text.substr(0, length) + "...";
};

const displayDate = (createdAt: string) => {
  let date = new Date(createdAt);
  let month = date.toLocaleString("default", { month: "long" });
  return `${month} ${date.getDate()}, ${date.getFullYear()}`;
};

const PrayerRequest = React.forwardRef<HTMLDivElement, PrayerRequestProps>(
  (
    { entry, incrementPrayedForCount, handleSelectEntry, handleEntryStyle },
    ref
  ) => {
    const [isCollapsed, setIsCollapsed] = useState(true);
    const [copied, setCopied] = useState(false);
    const [showOriginalPrayer, setShowOriginalPrayer] = useState(true);

    const isCollapsible = entry.prayer_request.length > 150;
    const nameForRequest =
      entry.sharing_option === "everyone" &&
      (entry.first_name || entry.name) &&
      (entry.first_name || entry.name);
    const shareURL = `prayermap.com/requests/${entry.uuid}`;
    let requestText = isCollapsed
      ? trimRequestText(entry.prayer_request)
      : entry.prayer_request;

    const handleShowOriginalPrayer = () => setShowOriginalPrayer(true);

    const handleCopyClick = () => {
      navigator.clipboard.writeText(
        `${window.location.origin}/requests/${entry.uuid}`
      );
      setCopied(true);
      setTimeout(() => setCopied(false), 3000);
    };

    return (
      <Request
        onClick={handleSelectEntry(entry)}
        ref={ref}
        className={classNames(
          {
            answered: entry.answered,
          },
          handleEntryStyle
        )}
      >
        <div className="map-pointer">
          <div className="map-pointer-inner" />
        </div>
        <RequestSelectableArea>
          <RequestHeader>
            <div>
              <h4>{nameForRequest || "Anonymous"}</h4>
              {entry.answered && !showOriginalPrayer && (
                <small onClick={handleShowOriginalPrayer}>
                  Click to see original prayer
                </small>
              )}
            </div>
            <RequestDate>
              Received: {displayDate(entry.created_at)}{" "}
              {entry.answered && (
                <>
                  <br />
                  Updated: {displayDate(entry.updated_at)}
                </>
              )}
            </RequestDate>
          </RequestHeader>
          <RequestText
            className={isCollapsible ? "collapsible" : ""}
            onClick={() => setIsCollapsed(!isCollapsed)}
          >
            <span
              style={{
                fontStyle:
                  entry.answered && showOriginalPrayer ? "italic" : "initial",
              }}
            >
              {requestText}
            </span>
            {isCollapsed && isCollapsible && (
              <RequestTextExpand>Click to expand</RequestTextExpand>
            )}
            {!isCollapsed && isCollapsible && (
              <RequestTextExpand>Click to collapse</RequestTextExpand>
            )}
          </RequestText>
          {entry.answered &&
          entry.answered_prayer_description.length &&
          showOriginalPrayer ? (
            <RequestText>
              <strong>Answer: </strong>
              {entry.answered_prayer_description}
            </RequestText>
          ) : null}
        </RequestSelectableArea>
        <RequestActionRow>
          <div>
            <RequestPrayedForCount>
              Prayed For: {entry.prayed_for_count} Times
            </RequestPrayedForCount>
            <div>
              <p>Share on Social Media:&nbsp;</p>
              <TwitterShareButton
                url={shareURL}
                title={`Join me in prayer for ${
                  nameForRequest || "this person"
                } 🙏`}
                className="social-button twitter"
              >
                <img
                  src={XIcon}
                  style={{ width: "16px", margin: "5px" }}
                  alt="X icon"
                />
              </TwitterShareButton>
              <FacebookShareButton
                url={shareURL}
                quote={`Join me in prayer for ${
                  nameForRequest || "this person"
                } 🙏`}
                className="social-button facebook"
              >
                <img
                  src={FbIcon}
                  style={{ width: "20px" }}
                  alt="Facebook icon"
                />
              </FacebookShareButton>
              <CopyUrlButton onClick={handleCopyClick}>
                {copied ? (
                  <span>Copied!</span>
                ) : (
                  <FontAwesomeIcon icon={faCopy} />
                )}
              </CopyUrlButton>
            </div>
          </div>
          <div>
            {entry.answered ? (
              <RequestAnswered>
                <FontAwesomeIcon icon={faCheck} /> Answered
              </RequestAnswered>
            ) : entry.has_prayed_for ? (
              <RequestThankYou>Thank you for praying.</RequestThankYou>
            ) : (
              <Button
                title="I prayed for this"
                onClick={() => incrementPrayedForCount(entry)}
              >
                <FontAwesomeIcon icon={faPrayingHands} />
              </Button>
            )}
          </div>
        </RequestActionRow>
      </Request>
    );
  }
);

export default PrayerRequest;
