import styled from "styled-components";

export const FiltersInputWrapper = styled.div`
    padding: 1rem;

    @media screen and (max-width: 768px) {
        padding: 0.6rem;
    }
`;

export const FiltersLabel = styled.label`
  color: #000000;
  font-size: 0.9rem;
  font-weight: 500;
  display: block;

  &.isNota {
    color: white;
  }
`;

export const FiltersToggleButtonWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    background: linear-gradient(0deg, #FFFFFF, #FFFFFF), linear-gradient(0deg, #FFFFFF, #FFFFFF), #FFFFFF;
    border: 1px solid #D8D8D8;
    border-radius: 9px;
    margin-bottom: 1rem;
`;

export const FiltersToggleButton = styled.button`
    color: #000000;
    font-size: 0.9rem;
    font-weight: 400;
    background: transparent;
    opacity: 0.4;
    transition: color 0.2s;
    flex: 1;

    &.selected {
        opacity: 1;
        font-weight: 600;
    }

    @media screen and (max-width: 768px) {
        padding: 0.6rem 0;
    }
`;
