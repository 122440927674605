import styled from "styled-components";

export const Wrapper = styled.div`
  width: 90%;
  position: relative;
  background-color: #f7f7f7;
  top: calc(${({ theme }) => theme.utilities?.spacing?.["space-sm"]} * 1.5);
  bottom: calc(${({ theme }) => theme.utilities?.spacing?.["space-sm"]} * 1.5);
  right: calc(${({ theme }) => theme.utilities?.spacing?.["space-sm"]} * 1.5);
  left: calc(${({ theme }) => theme.utilities?.spacing?.["space-sm"]} * 1.5);
  padding: ${({ theme }) => theme.utilities?.spacing?.["space-md"]};
  border-radius: ${({ theme }) =>
    theme?.utilities?.borders?.["border-radius-md"]};
  display: flex;
  flex-direction: column;
  align-items: left;
`;

export const FormWrapper = styled.div`
  width: 100%;
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
  margin-top: ${({ theme }) => theme.utilities?.spacing?.["space-sm"]};
  gap: 1.5rem;

  @media (max-width: 768px) {
    h3 {
      text-align: center;
    }
  }
`;

export const Dialogue = styled.label`
  display: flex;
  align-items: start;
  color: #1f356e;
  p {
    font-style: italic;
    font-size: 0.8rem;
  }
  img {
    width: 0.8rem;
    margin-right: 5px;
    margin-top: 5px;
  }
`;

export const Title = styled.h1`
  font-size: 2rem;
  font-weight: bold;
  color: black;
  line-height: 1.2;
`;

export const Description = styled.p`
  font-size: 0.875rem;
  color: black;
`;

export const RequestHeader = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: ${({ theme }) => theme.utilities?.spacing?.["space-sm"]};

  h4 {
    float: left;
    font-size: 0.875rem;

    @media (min-width: 1440px) {
      font-size: 1rem;
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const RequestDate = styled.p`
  font-size: 0.65rem;
  align-self: left;
  margin-left: auto;

  @media (min-width: 1440px) {
    font-size: 0.75rem;
  }

  @media (max-width: 768px) {
    margin-left: 0;
    align-self: left;
    margin-bottom: ${({ theme }) => theme.utilities?.spacing?.["space-sm"]};
  }
  @media (min-width: 1024px) {
    align-self: center;
  }
`;

export const RequestText = styled.p`
  margin: ${({ theme }) => theme.utilities?.spacing?.["space-xs"]} 0;
  font-weight: light;
  font-size: 0.875rem;

  & span {
    text-decoration: underline;
  }

  @media (min-width: 1440px) {
    font-size: 0.875rem;
  }

  @media (max-width: 768px) {
    text-align: left;
  }
`;

export const FormMessage = styled.p`
  font-size: 0.75rem;
  margin-bottom: -25px;
  color: black;
`;

export const FormApprovalDisclaimer = styled.p`
  color: #000;
  font-size: 0.75rem;
  margin-right: calc(-${({ theme }) => theme.utilities?.spacing?.["space-md"]} * 1.5);
  font-style: italic;
  padding-right: calc(${({ theme }) => theme.utilities?.spacing?.["space-lg"]});
`;