import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
    faGlobeAmericas
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, {useEffect, useState, UIEvent} from "react";
import MapSidebarFilters from "./mapSidebarFilters";
import { AnsweredStatus, MapView } from "./mapSidebarFilters/mapSidebarFilters";
import { NotaSidebar, NotaSidebarToggle, NotaSidebarWrapper } from "./styles";
import RequestPopout from "../prayerRequest/popout/popout";

type NotaMapSidebarProps =  {
    filtersIsOpen: boolean;
    setListIsOpen: (isOpen: boolean) => void;
    mapData: any[];
    getMoreMapData:() => void;
};

const NotaMapSidebar: React.FC<NotaMapSidebarProps> = ({
filtersIsOpen,
    setListIsOpen,
    mapData,
    getMoreMapData
}) => {
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
      setListIsOpen(isOpen);
  }, [isOpen]);
    const [scrollNumber, setScrollNumber] = useState(0);
    useEffect(() => {
        if (scrollNumber > 60) {
            getMoreMapData();
        }
    }  , [scrollNumber])

    function handleScroll(event:UIEvent<HTMLDivElement>) {
        setScrollNumber(Math.floor((event.currentTarget.scrollTop / event.currentTarget.scrollHeight) * 100))
    }

  return (
    <NotaSidebarWrapper className={`${isOpen && "open"} ${filtersIsOpen && 'filtersIsOpen'}`}>
      <NotaSidebarToggle
        className={`btn toggle ${isOpen && "togglerIsOpen"}`}
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        <FontAwesomeIcon
          icon={faGlobeAmericas as IconProp}
        />
      </NotaSidebarToggle>
      <NotaSidebar onScroll={(e)=>{handleScroll(e)}}>
          {mapData.map((item) => {
              return (
                    <div className={'notaPopoutWrapper'} key={item.uuid}>
                        <RequestPopout
                            {...{ selectedEntry:item }}
                            {...{ incrementPrayedForCount: () => {} }}
                            {...{ leaving:false }}
                            {...{ handleLeave: () => {}  }}
                            isNota={true}
                            isNotaSideBar={true}
                            key={item.uuid}
                        />
                    </div>
                )
          })}
      </NotaSidebar>
    </NotaSidebarWrapper>
  );
};

export default NotaMapSidebar;
