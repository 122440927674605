import getApiUrl from "../utils/getApiUrl";
import makeRequest from "./make-request";

const authURL = getApiUrl(
  process.env.REACT_APP_AUTH_URL,
  "http://localhost:80/api/v1/auth",
  true
);

export type LoginResponse = {
  email: string;
  name: {
    first: string;
    last: string;
  };
  bearer: string;
};

const login = async (email: string, password: string) => {
  return makeRequest(authURL, "/login", "post", {
    email,
    password,
  }) as unknown as LoginResponse;
};

const authClient = {
  login,
};

export default authClient;
