import Button from "../../../components/button";
import { faEnvelope, faLock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  LoginError,
  LoginInput,
  LoginInputWrapper,
  LoginWrapper,
} from "./login.styles";
import { useState } from "react";

type LoginProps = {
  onLogin: (email: string, password: string) => void;
  loginError?: string | null;
};

const Login: React.FC<LoginProps> = ({ onLogin, loginError }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  return (
    <LoginWrapper>
      <h4>You need to log in to access this page.</h4>
      <LoginInputWrapper>
        <LoginInput
          placeholder="Email"
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
        />
        <FontAwesomeIcon icon={faEnvelope} className="input-icon" />
      </LoginInputWrapper>
      <LoginInputWrapper>
        <LoginInput
          placeholder="Password"
          type="password"
          value={password}
          onChange={(e) => {
            setPassword(e.target.value);
          }}
        />
        <FontAwesomeIcon icon={faLock} className="input-icon" />
      </LoginInputWrapper>
      <Button
        title="Log In"
        onClick={() => onLogin(email, password)}
        disabled={email.length === 0 && password.length === 0}
      />
      {loginError && <LoginError>{loginError}</LoginError>}
    </LoginWrapper>
  );
};

export default Login;
