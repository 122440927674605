import React from "react";
import styled from "styled-components";
import RequestForm from "../../components/requestForm";
import useGeolocation from "../../hooks/useGeolocation";
import useQuery from "../../hooks/useQuery";

const FullscreenWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #6f727380;
`;

// Purpose: Embedding the prayer request form on other sites. Not being used atm.
const EmbedForm = () => {
  const location = useGeolocation();
  const query = useQuery();

  const isFullscreen = query.get("fullscreen");

  const Wrapper = isFullscreen === "true" ? FullscreenWrapper : React.Fragment;

  return (
    <Wrapper>
      <RequestForm geolocation={location} />
    </Wrapper>
  );
};

export default EmbedForm;
