/**
 *
 * @param remoteURL The remote URL, should come from 'process.env'
 * @param overrideUrl The override URL to use locally
 * @param useLocalRemoteURL Whether to use the remote URL locally
 * @returns The appropriate
 */
const getApiUrl = (
  remoteURL: string | undefined,
  overrideUrl: string,
  useLocalRemoteURL: boolean
) => {
  //Always return remote URL if running in production
  if (process.env.NODE_ENV === "production") {
    return remoteURL!;
  }

  return useLocalRemoteURL && remoteURL ? remoteURL : overrideUrl;
};

export default getApiUrl;
