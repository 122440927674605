import { useEffect, useState } from "react";
import serverClient from "../../../api/serverClient";
import CountryService from "../../../services/country-service";
import { ArcMapFilters } from "./useArcMapFilters";

type Arc = {
  profile: any;
  arc: {
    uuid: string;
    coords: number[];
  };
};

type ArcMapData = {
  profiles: any[];
  arcs: Arc[];
};

export type Filters = { [key: string]: string[] };

const useArcMapData = (
  filters: ArcMapFilters,
  inheritedFilters: Filters,
  loadingFilters: boolean
) => {
  const [data, setData] = useState<ArcMapData>({
    profiles: [],
    arcs: [],
  });
  const [loading, setLoading] = useState(true);

  const getRequestPartnerName = () => {
    return "all";
  };

  const fetchMapData = async () => {
    setLoading(true);
    let mapData = (await serverClient.getArcData(
      filters.currentFilters["Date Range"],
      "prayerWall",
      getRequestPartnerName(),
      inheritedFilters
    )) as any[];

    let parsedMapData = parseArcData(CountryService.parseLocationData(mapData));

    // Filters the fetched data locally
    // TODO: REFACTOR THIS
    if (inheritedFilters.answeredStatus[0] === 'answered') {
      parsedMapData.profiles = parsedMapData.profiles.filter(p => p.answered);
    } else if (inheritedFilters.answeredStatus[0] === 'unanswered') {
      parsedMapData.profiles = parsedMapData.profiles.filter(p => !p.answered);
    }

    setData(parsedMapData);
    setLoading(false);
  };

  const parseArcData = (data: any[]): { profiles: any[]; arcs: Arc[] } => {
    let profiles = [];
    let arcData = [];

    for (let row of data) {
      const { arcs, ...profile } = row;
      profiles.push(profile);
      arcData.push(...arcs.map((arc: Arc) => checkArcDataCoords(profile, arc)));
    }

    return {
      profiles,
      arcs: arcData,
    };
  };

  const checkArcDataCoords = (profile: any, arc: any) => {
    if (
      profile.coords[0] === arc.coords[0] &&
      profile.coords[1] === arc.coords[1]
    ) {
      arc.coords[0] += Math.random() - 0.5;
      arc.coords[1] += Math.random() - 0.5;
    }

    return {
      profile,
      arc,
    };
  };

  useEffect(() => {
    if (!loadingFilters) {
      fetchMapData();
    }
    //eslint-disable-next-line
  }, [filters.currentFilters, inheritedFilters, loadingFilters]);

  return {
    data,
    loading,
  };
};

export default useArcMapData;
