import jwtDecode from "jwt-decode";
import { useEffect, useState } from "react";
import authClient, { LoginResponse } from "../api/authClient";

const localStorageKey = "ul-prayer-wall-admin";

type Bearer = {
  bearerData: {
    role: string;
    partner_uuid: string;
  };
};

type User = LoginResponse & Bearer;

const blessedMediaPartnerUuid = "a23c3ba8-2868-11ec-a381-06511c58f600";

const useAuth = () => {
  const [currentUser, setCurrentUser] = useState<User>();
  const [loginError, setLoginError] = useState<string | null>();

  useEffect(() => {
    if (localStorage.getItem(localStorageKey)) {
      let result = JSON.parse(
        localStorage.getItem(localStorageKey)!
      ) as unknown as User;
      setCurrentUser(result);
    }
    // eslint-disable-next-line
  }, []);

  const login = async (email: string, password: string) => {
    // check localStorage first;
    let result = await authClient.login(email, password).catch(() => {
      localStorage.removeItem(localStorageKey);
      return null;
    });

    if (!result) {
      setLoginError("Invalid Email/Password");
      return;
    }
    let bearer = jwtDecode(result.bearer) as Bearer["bearerData"];
    if (
      bearer.role === "admin" ||
      bearer.partner_uuid === blessedMediaPartnerUuid
    ) {
      setCurrentUser({ ...result, bearerData: bearer });
      localStorage.setItem(
        localStorageKey,
        JSON.stringify({ ...result, bearerData: bearer })
      );
      setLoginError(null);
    } else {
      setLoginError("You do not have permission to access this page");
    }

    return;
  };

  const logout = () => {
    setCurrentUser(undefined);
    localStorage.removeItem(localStorageKey);
    return;
  };

  return {
    login,
    logout,
    currentUser,
    setCurrentUser,
    loginError,
  };
};

export default useAuth;
