import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { PrayerWallEntry } from "../../../types/prayerWallEntry";
import PrayerRequest from "../prayerRequest";

type PrayerRequestsWrapperProps = {
  entries: Array<any>;
  selectedEntry?: PrayerWallEntry | null;
  incrementPrayedForCount: (entry: PrayerWallEntry) => any;
  setCurrentSelectedEntry?: (entry: PrayerWallEntry) => any | null;
  handleSelectEntry: (entry: PrayerWallEntry) => () => any;
  prayerRequestRefs: any;
  setSearch?: any | null;
};

const PrayerRequestsWrapper: React.FC<PrayerRequestsWrapperProps> = ({
  entries,
  selectedEntry,
  prayerRequestRefs,
  incrementPrayedForCount,
  setCurrentSelectedEntry,
  handleSelectEntry,
  setSearch,
}) => {
  const params: any = useParams();

  useEffect(() => {
    params.uuid && setSearch(params.uuid);
  }, []);

  useEffect(() => {
    if (entries.length === 1 && entries[0]?.uuid === params?.uuid) {
      setCurrentSelectedEntry && setTimeout(() => setCurrentSelectedEntry(entries[0]), 250);
    };
  }, [entries]);

  return (
    <>
      {entries.map((entry) => (
        <PrayerRequest
          key={entry.uuid}
          entry={entry}
          ref={prayerRequestRefs[entry.uuid]}
          incrementPrayedForCount={incrementPrayedForCount}
          handleSelectEntry={handleSelectEntry}
          handleEntryStyle={
            selectedEntry?.uuid === entry.uuid
              ? "selected"
              : "not-selected"
          }
        />
      ))}
    </>
  );
};

export default PrayerRequestsWrapper;
