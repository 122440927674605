import styled, { keyframes } from "styled-components";
// import { PrayerWallProfile } from "../seekerProfile/prayerWallSeekerProfile/prayerWallSeekerProfile.styles";

export const ArcMapWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;

  .profile-tooltip {
    filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.5));
    transform: translateY(-50%);
    background-color: ${({ theme }) => theme?.colors["ui-gray-lighter"]};

    border-radius: ${({ theme }) =>
      theme?.utilities.borders["border-radius-sm"]};
    padding: ${({ theme }) => theme?.utilities.spacing["space-xs"]}
      ${({ theme }) => theme?.utilities.spacing["space-sm"]};
    h5 {
      color: ${({ theme }) => theme?.colors["primary-dark"]};
      white-space: nowrap;
      font-weight: 500;
    }
    p {
      font-size: 0.65rem;
      opacity: 0.8;
      &.blue {
        color: ${({ theme }) => theme?.colors["primary-dark"]};
      }

      &.orange {
        color: ${({ theme }) => theme?.colors["secondary-default"]};
      }

      &.green {
        color: #22814f;
      }
    }
  }
`;

export const ModalEnter = keyframes`
  0% {
    transform: translateX(-50vw);
  }
  100% {
    transform: translateY(0);
  }
`;

export const ModalLeave = keyframes`
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateX(-50vw);
  }
`;

export const PrayerRequestWrapper = styled.div`
  position: absolute;
  bottom: ${({ theme }) => theme?.utilities.spacing["space-lg"]};
  left: ${({ theme }) => theme?.utilities.spacing["space-lg"]};
`;

export const MapKey = styled.div`
  position: absolute;
  bottom: ${({ theme }) => theme?.utilities.spacing["space-lg"]};
  left: ${({ theme }) => theme?.utilities.spacing["space-sm"]};
  padding: ${({ theme }) => theme?.utilities.spacing["space-sm"]};
  display: grid;
  grid-template-columns: 1fr auto;
  column-gap: ${({ theme }) => theme?.utilities.spacing["space-xs"]};
  row-gap: ${({ theme }) => theme?.utilities.spacing["space-xs"]};
  align-items: center;
  justify-items: center;
  background-color: #00000090;
  border-radius: ${({ theme }) => theme?.utilities.borders["border-radius-md"]};
  transition: 0.3s ease;

  &.sidebar-open {
    right: calc(${({ theme }) => theme?.utilities.spacing["space-lg"]} + 420px);
  }

  .map-key-item {
    color: white;
    font-weight: 700;
    font-size: 0.85rem;
    width: 100%;
    text-align: left;
  }

  .item-color {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid white;
    margin-left: 5px;

    &.white {
      background-color: white;
    }
    &.blue {
      background-color: #2697B8;
    }
    &.orange {
      background-color: rgb(253, 165, 51);
    }
    &.green {
      background-color: #43C779;
    }
  }

  @media (max-width: 1024px) {
    display: none;
  } 
`;

export const MapKeyNota = styled.div`
  position: absolute;
  top: 0;
  display: flex;
  width: 100%;
  justify-content: center;
  background: linear-gradient(80deg, #05455f, #08516f);
  border-bottom-left-radius: 25%;
  border-bottom-right-radius: 25%;

  ul {
    display: flex;
    gap: ${({ theme }) => theme?.utilities.spacing["space-xs"]};
    padding: ${({ theme }) => theme?.utilities.spacing["space-sm"]};
  }

  li {
    display: flex;
    align-items: center;
  }

  .map-key-item {
    color: white;
    font-weight: 700;
    font-size: 0.6rem;
    text-align: left;
    flex-shrink: 1;
  }

  .item-color {
    width: 20px;
    height: 20px;
    flex-shrink: 0;
    border-radius: 50%;
    border: 1px solid white;
    margin-right: 15px;

    &.white {
      background-color: white;
    }
    &.blue {
      background-color: #2697b8;
    }
    &.orange {
      background-color: #fda533;
    }
    &.green {
      background-color: #43c779;
    }
  }
`;

export const MapKeyNotaDecoration = styled.div`
  position: absolute;
  top: 0;
  right: 12px;
  width: 86px;
  height: 12px;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  background-color: #277b9d;
`;