import styled from "styled-components";
import { FormInput } from "../requestForm/requestForm.styles";

export const SearchBarWrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;

  .search-icon {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }
`;

export const SearchBarInput = styled(FormInput)`
  background-color: transparent;
  padding-left: 25px;

  border: none;
  outline: none;
  border-bottom: 1px solid #1f356e;
  border-radius: 0;

  ::placeholder {
    color: #000;
  }

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

export const SearchBarResetButton = styled.button`
  background: transparent;
  border: none;
  width: 35px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  padding: 10px;
  font-size: 0.9rem;
  opacity: 0.7;
  cursor: pointer;
`;
