import WhatsAppIcon from "../../media/whatsapp-icon.svg";
import SmsIcon from "../../media/sms-icon.svg";
import EmailIcon from "../../media/envelope-icon.svg";
import { FormInputConfig } from "./formConfig";
import {
  FormCheckbox,
  FormCheckBoxWrapper,
  FormDropdown,
  FormInput,
  FormInputInfo,
  FormInputRow,
  FormItemLabel,
  FormLabel,
  FormTextArea,
  IconRadioButtonWrapper,
  RadioButton,
  RadioOption,
} from "./requestForm.styles";
import circleInfo from "../../media/circle-info.svg";

const formComponentFactory = (
  config: FormInputConfig,
  responses: { [key: string]: any },
  onInputChange: (key: string, value: any) => void
) => {
  let formComponent;
  switch (config.type) {
    case "row":
      formComponent = (
        <FormInputRow key={config.accessorKey}>
          {config.rowChildren?.map((child) => (
            <div>{formComponentFactory(child, responses, onInputChange)}</div>
          ))}
        </FormInputRow>
      );
      break;
    case "input":
      formComponent = (
        <>
          <FormInput
            value={responses[config.accessorKey]}
            onChange={(e) => onInputChange(config.accessorKey, e.target.value)}
            placeholder={config.placeholder}
          />
          {config.accessorKey === "last_name" && (
            <FormInputInfo>
              <img alt="information" src={circleInfo} />
              <p>Last names are <span>not</span> displayed publicly.</p>
            </FormInputInfo>
          )}
        </>
      );
      break;
    case "dropdown":
      formComponent = (
        <FormDropdown
          value={responses[config.accessorKey]}
          onChange={(e) => onInputChange(config.accessorKey, e.target.value)}
        >
          {config.options?.map(([value, label]) => (
            <option value={value} key={value}>
              {label}
            </option>
          ))}
        </FormDropdown>
      );
      break;
    case "textarea":
      formComponent = (
        <FormLabel>
          <FormTextArea
            role="textbox"
            placeholder={config.placeholder}
            contentEditable
            onInput={(e) =>
              onInputChange(config.accessorKey, e.currentTarget.innerText)
            }
          />
        </FormLabel>
      );
      break;
    case "checkbox":
      formComponent = (
        <FormCheckBoxWrapper>
          <FormCheckbox
            type="checkbox"
            checked={responses[config.accessorKey]}
            onChange={() =>
              onInputChange(config.accessorKey, !responses[config.accessorKey])
            }
          />
          {config.placeholder}
        </FormCheckBoxWrapper>
      );
      break;
    case "radiobox":
      type IconMapper = "email" | "whatsapp" | "sms";
      const iconMapper = {
        email: EmailIcon,
        whatsapp: WhatsAppIcon,
        sms: SmsIcon,
      };
      let options = config.options;
      const isContactMethod = config.accessorKey === "preferred_contact_method";
      if (isContactMethod) {
        options = config.options?.filter(([value, label]) =>
          value === "email" ? !!responses.email : !!responses.phone
        );
      }
      formComponent = (
        <IconRadioButtonWrapper>
          {options &&
            options.map(([value, label], i: number) => (
              <RadioOption key={i}>
                <label htmlFor={"icon-radio-button-" + value}>
                  <img
                    src={iconMapper[value as IconMapper]}
                    alt={value + "logo"}
                  />
                  <p>{label}</p>
                  <input
                    id={"icon-radio-button-" + value}
                    type={"radio"}
                    value={value}
                    name={config.accessorKey}
                    onChange={(e) =>
                      onInputChange(config.accessorKey, e.target.value)
                    }
                  />
                </label>
              </RadioOption>
            ))}
        </IconRadioButtonWrapper>
      );
      break;
    case "radio":
      formComponent = (
        <div className="rdcomponent">
          {config.options &&
            config.options.map(([value, label], i: number) => (
              <label htmlFor={"radio-button-" + value}>
                <RadioButton key={i}>
                  <input
                    id={"radio-button-" + value}
                    type={"radio"}
                    value={value}
                    name={config.accessorKey}
                    onChange={(e) =>
                      onInputChange(config.accessorKey, e.target.value)
                    }
                  />
                  <p>{label}</p>
                </RadioButton>
              </label>
            ))}
        </div>
      );
      break;
  }

  return (
    formComponent && (
      <>
        {config.type !== "row" && <FormItemLabel>{config.name}</FormItemLabel>}
        {formComponent}
      </>
    )
  );
};

export default formComponentFactory;
