import { FormInputConfig } from "../../requestForm/formConfig";

export const formItems: FormInputConfig[] = [
  {
    placeholder: "Please write your update here",
    accessorKey: "description",
    required: false,
    requiresValidation: false,
    type: "textarea",
    validationErrorMessage: "Please enter an update",
  },
];

export const THANK_YOU_MESSAGE =
  "Your request has been submitted and will be reviewed shortly. In the meantime, why not pray for some other requests?";
