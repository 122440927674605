import "mapbox-gl/dist/mapbox-gl.css";
//@ts-ignore
import DeckGL, { FlyToInterpolator } from "deck.gl";
//@ts-ignore
import StaticMap from "react-map-gl";
import { useEffect, useRef, useState } from "react";
import ZoomControl from "./zoom-control";
import { MapLoading, MapLoadingSmall, MapTitle, MapWrapper } from "./styles";
import useMapControls from "./hooks/useMapControls";
import Loader from "../loader";
// import { useView } from "hooks/globalContext";
// import Spinner from "components/spinner";

type InteractiveMapProps = {
  title: string;
  loading: boolean;
  continueLoading?: boolean;
  layers: any[];
  selectedCountry: string;
  canSelectCountry?: boolean;
  setSelectedCountry: (country: string) => void;
  hasZoomControl?: boolean;
  selectedItem?: any;
  mapView?: string;
  renderTooltip: ({ object }: any) => any;
  dashboardMode?: boolean;
  onViewStateChange?: (viewState: any) => void;
  mapType?: string;
  zoomedCountry?: string;
  onZoomChange?: (size: number) => void
};

const InteractiveMap: React.FC<InteractiveMapProps> = ({
  title,
  loading,
  continueLoading,
  layers,
  selectedCountry,
  setSelectedCountry,
  canSelectCountry = true,
  hasZoomControl = true,
  selectedItem = null,
  mapView = "default",
  renderTooltip = () => {},
  dashboardMode,
  children,
  onViewStateChange,
  mapType,
  zoomedCountry = null,
    onZoomChange
}) => {
  const mapRef = useRef();

  const [highlightedCountry, setHighlightedCountry] = useState("");
  const [mapLayers, setMapLayers] = useState<any[]>([]);

  // const { currentView } = useView();

  const {
    viewState,
    setViewState,
    userViewState,
    moveToCountry,
    onItemHover,
    countryLayer,
  } = useMapControls(
    highlightedCountry,
    setHighlightedCountry,
    selectedCountry,
    setSelectedCountry,
    canSelectCountry
  );

  useEffect(() => {
    const newLayers = layers.map(({ LayerClass, hoverTypeName, ...layerConfig }) => {
      return new LayerClass({
        ...layerConfig,
        onHover: ({ object }: any) => onItemHover(object, hoverTypeName),
      })
    });
    setMapLayers(newLayers);
    //eslint-disable-next-line
  }, [layers]);

  useEffect(() => {
    if (selectedCountry) {
      moveToCountry(selectedCountry);
    }
    //eslint-disable-next-line
  }, [selectedCountry]);

  useEffect(() => {
    if (!selectedItem) {
      setViewState(zoomOutViewState());
    }
    //eslint-disable-next-line
  }, [selectedItem]);

  useEffect(() => {
    if (mapView !== "hex3d") {
      setViewState({
        ...viewState,
        bearing: 0,
        pitch: 0,
        minPitch: 0,
        maxPitch: 0,
      });
    } else {
      setViewState({
        ...viewState,
        pitch: 90,
        minPitch: 60,
        maxPitch: 60,
      });
    }
    //eslint-disable-next-line
  }, [mapView]);

  const zoomOutViewState = () => {
    if (userViewState !== null && !dashboardMode) {
      return {
        ...userViewState,
        width: window.innerWidth,
        height: window.innerHeight,
        transitionInterpolator: new FlyToInterpolator(),
        transitionDuration: 1500,
      };
    } else {
      return {
        minZoom: window.matchMedia("(min-width: 1024px)").matches ? 1 : 1.75,
        latitude: 25,
        longitude: 0,
        zoom: window.matchMedia("(min-width: 1024px)").matches ? 1 : 1.75,
        width: window.innerWidth,
        height: window.innerHeight,
        transitionInterpolator: new FlyToInterpolator(),
        transitionDuration: 1500,
      };
    }
  };

  const handleViewStateChange = (viewState: any) => {
    if (onZoomChange !== undefined) {
      /*let newZoom = .7
      switch (viewState.zoom){
        case viewState.zoom > 1.7 && viewState.zoom < 5: {
          newZoom = .7
          break
        }
        case viewState.zoom >= 5 && viewState.zoom < 10: {
          newZoom = 1
          break
        }
      }*/
      const base = 10; // choose any positive value greater than 1
      const iconSize = 0.7 + 0.8 * Math.log(viewState.zoom / 1.75) / Math.log(base);
      //console.log({iconSize})
      onZoomChange(iconSize)
    }
    onViewStateChange && onViewStateChange(viewState);
    setViewState({
      ...viewState,
      pitch: 60,
      minPitch: 60,
      maxPitch: 60,
    });
  };

  // Check specific media-query to control minimum value for zoom.
  useEffect(() => {
    const mql = window.matchMedia("(max-width: 1024px)");
    mql.addEventListener("change", resize);
    function resize(e: any) {
      if (e.matches) { // If media query matches
        setViewState({
          ...viewState,
          minZoom: 1,
          zoom: 1,
        });
      } else {
        setViewState({
          ...viewState,
          minZoom: 1.75,
          zoom: 1.75,
        });
      }
    }
    return () => {
      mql.removeEventListener("change", resize);
    }
    //eslint-disable-next-line
  },[]);

  return (
    <MapWrapper>
      <MapTitle>
        <h2>{title}</h2>
      </MapTitle>
      <DeckGL
        viewState={viewState}
        onViewStateChange={(e: any) => handleViewStateChange(e.viewState)}
        controller={true}
        layers={[countryLayer, ...mapLayers]}
        ref={mapRef}
        //@ts-ignore
        getCursor={({ isDragging, isHovering }) =>
          isDragging ? "grabbing" : isHovering ? "pointer" : "grab"
        }
        getTooltip={renderTooltip}
      >
        <StaticMap
          reuseMaps
          mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
          mapStyle={process.env.REACT_APP_MAPBOX_STYLE_URL}
          preventStyleDiffing={true}
        />
      </DeckGL>
      {hasZoomControl && (
        <ZoomControl viewport={viewState} setViewport={handleViewStateChange} />
      )}
      {children}
      {loading && (
        <MapLoading>
          <Loader />
        </MapLoading>
      )}
      {continueLoading && (
        <MapLoadingSmall>
          <Loader />
        </MapLoadingSmall>
      )}
    </MapWrapper>
  );
};

export default InteractiveMap;
