import styled from "styled-components";

export const FullMapWrapper = styled.div`
  width: 100%;
  height: 100vh;
  position: relative;
  z-index: 0;

  @media (max-width: 1024px) {
    height: 100vh;
  }

  &.go-head-fs {
    height: 100vh;
  }
`;

export const MapContent = styled.div`
  width: 100%;
  height: 100%;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  position: absolute;
  z-index: 0;
`;

export const MapOverlay = styled.div`
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, #000000 31.57%, rgba(0, 0, 0, 0.38) 100%);
  position: absolute;
  z-index: 10;

  .overlay-content {
    overflow: hidden;
    max-width: 425px;
    padding: 0 1rem;
    margin: 6rem 3rem;

    @media (max-width: 768px) {
      max-width: inherit;
      margin-top: 2.5rem;
      margin-bottom: 3rem;
    }

    @media (max-width: 1024px) and (max-height: 512px) {
      max-width: inherit;
      margin-top: 5rem;
      margin-bottom: 3rem;
    }
  }

  h2 {
    color: #88D0FF;
    font-size: 1.75rem;
    font-weight: 800;
    text-transform: uppercase;
    line-height: 1.2;
    margin-bottom: 1rem;

    @media (min-width: 1440px) {
      font-size: 2.25rem;
    }

    @media (max-width: 1024px) {
      font-size: 1.5rem;
      margin-bottom: 10px;
    }

    @media (max-width: 400px) and (max-height: 800px) {
      font-size: 1rem;
      margin-bottom: 15px;
    }
  }

  p {
    color: #F6F6F6;
    font-size: 0.95rem;
    line-height: 1.2;
    margin-bottom: 25px;

    @media (min-width: 1440px) {
      font-size: 1rem;
    }

    @media (max-width: 1024px) {
      font-size: 0.875rem;
      margin-bottom: 15px;
    }

    @media (max-width: 450px) and (max-height: 800px) {
      font-size: 0.75rem;
      margin-bottom: 15px;
    }

    @media (max-width: 375px) {
      font-size: 0.7rem;
      margin-bottom: 15px;
    }
  }
`;

export const MapControllers = styled.div`
  width: 100%;
  height: 50px;
  background: rgba(0, 0, 0, .25);
  position: absolute;
  display: block;
  z-index: 1;
`;

export const GoFullscreenBtn = styled.div`
  display: block;
  cursor: pointer;
  position: absolute;
  margin-top: ${({ theme }) => theme.utilities?.spacing?.["space-xs"]};
  margin-right: ${({ theme }) => theme.utilities?.spacing?.["space-sm"]};
  width: 30px;
  right: 0;
  top: 0;
`;

export const ExitFullscreenBtn = styled.div`
  display: none;
  cursor: pointer;
  position: absolute;
  margin-top: ${({ theme }) => theme.utilities?.spacing?.["space-xs"]};
  margin-right: ${({ theme }) => theme.utilities?.spacing?.["space-sm"]};
  width: 30px;
  right: 0;
  top: 0;
`;

export const OpenFSSidebarBtn = styled.div`
  display: none;
  cursor: pointer;
  position: absolute;
  margin-top: ${({ theme }) => theme.utilities?.spacing?.["space-xs"]};
  margin-right: ${({ theme }) => theme.utilities?.spacing?.["space-sm"]};
  width: 30px;
  right: 0;
  top: 0;
`;

export const CloseFSSidebarBtn = styled.div`
  display: none;
  cursor: pointer;
  position: absolute;
  margin-top: ${({ theme }) => theme.utilities?.spacing?.["space-xs"]};
  margin-right: ${({ theme }) => theme.utilities?.spacing?.["space-sm"]};
  width: 30px;
  right: 50%;
  top: 0;
`;

export const ClearMapSelection = styled.div`
  display: none;
  cursor: pointer;
  position: absolute;
  margin: ${({ theme }) => theme.utilities?.spacing?.["space-xs"]} ${({ theme }) => theme.utilities?.spacing?.["space-sm"]};
  background-color: #ff0000;
  height: 30px; width: 30px;
  border-radius: 50%;
  left: 0;
  top: 0;
`;