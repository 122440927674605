import {
  ButtonWrapper,
  FormApprovalDisclaimer,
  FormInvalidWarning,
  FormItemWrapper,
  FormWrapper,
} from "./requestForm.styles";
import Button from "../button";
import { FormInputConfig, formItems, REQUEST_THANK_YOU_MESSAGE } from "./formConfig";
import formComponentFactory from "./formComponentFactory";
import { useState } from "react";
import { Geolocation } from "../../hooks/useGeolocation";
import { useHistory, useLocation } from "react-router-dom";
import { usePrayerRequestContext } from "../../hooks/context/prayerRequest/prayerRequestContext";

type RequestFormProps = {
  geolocation?: Geolocation;
};

const RequestForm: React.FC<RequestFormProps> = ({ geolocation }) => {
  const history = useHistory();
  const location = useLocation();
  const searchParams = location.search;

  const [showValidation, setShowValidation] = useState(false);
  const {
    responses,
    validation,
    onInputChange,
    setThankYouMessage,
    generateNewEntry,
  } = usePrayerRequestContext();

  const onSubmitClick = async () => {
    setShowValidation(true);

    if (Object.values(validation).every((x) => x)) {
      await generateNewEntry();
      if (responses.connect_local_church) {
        history.push(`/submit-request/register-seeker${searchParams}`);
      } else {
        setThankYouMessage(REQUEST_THANK_YOU_MESSAGE);
        history.push(`/requests${searchParams}`);
      }
    };
  };

  const checkSubmitDisabled = (itemsToCheck: FormInputConfig[]):boolean => {
    let disabled: boolean = false
    for (let config of itemsToCheck) {
      if (config.type === "row" && config.rowChildren) {
        disabled = checkSubmitDisabled(config.rowChildren);
      } else if (
        config.required &&
        responses[config.accessorKey]?.length === 0
      ) {
        return true;
      }
    }
    return disabled;
  };

  return (
    <FormWrapper>
      {formItems.map((config, index) => (
        <FormItemWrapper key={index}>
          {formComponentFactory(config, responses, onInputChange)}
          {showValidation &&
            config.requiresValidation &&
            !validation[config.accessorKey] && (
              <FormInvalidWarning>
                {config.validationErrorMessage}
              </FormInvalidWarning>
            )
          }
        </FormItemWrapper>
      ))}
      <ButtonWrapper>
        <Button
          title="Submit Request"
          onClick={onSubmitClick}
          disabled={checkSubmitDisabled(formItems)}
        />
      </ButtonWrapper>
      <FormApprovalDisclaimer>
        Your prayer request will be reviewed before it is posted on the Prayer Map.
      </FormApprovalDisclaimer>
    </FormWrapper>
  );
};

export default RequestForm;
