import { useEffect, useState } from "react";
import Button from "../../../components/button";
import serverClient from "../../../api/serverClient";
import { PrayerWallEntry } from "../../../types";
import Loader from "../../../components/loader";
import {
  ApprovalListItem,
  ApprovalListItemButtonRow,
  ApprovalListItems,
  ApprovalListTitle,
  ApprovalListTotal,
  ApprovalListWrapper,
} from "./approvalList.styles";
import { capitalize } from "../../../utils/string-manipulation";
import dayjs from "dayjs";

type ApprovalListProps = {
  refresh: boolean;
};

const ApprovalList: React.FC<ApprovalListProps> = ({ refresh }) => {
  const [loading, setLoading] = useState(true);
  const [pending, setPending] = useState<PrayerWallEntry[]>([]);

  const getPending = async () => {
    setLoading(true);
    let result = await serverClient.getPendingRequests();
    const newestToOldest = result.sort((a, b) => {
      return +new Date(a.created_at) - +new Date(b.created_at);
    });
    const answeredEntriesLast = newestToOldest.reduce((acc: PrayerWallEntry[], entry: PrayerWallEntry) => {
      if (!entry.answered) {
        return [entry, ...acc];
      }
      return [...acc, entry]
    }, [])
    setPending(answeredEntriesLast);
    setLoading(false);
    console.log(answeredEntriesLast)
  };

  useEffect(() => {
    getPending();
  }, []);

  useEffect(() => {
    if (refresh) {
      setPending([]);
      getPending();
    }
    //eslint-disable-next-line
  }, [refresh]);

  const processRequest = async (uuid: string, approved: boolean) => {
    await serverClient.processRequest(uuid, approved);
    setPending([...pending.filter((x) => x.uuid !== uuid)]);
  };

  const approveAll = async () => {
    await serverClient.approveAllRequests();
    setPending([]);
  };

  return (
    <ApprovalListWrapper>
      <ApprovalListTitle>Requests awaiting approval:</ApprovalListTitle>
      {loading ? (
        <Loader />
      ) : (
        <ApprovalListTotal>{pending?.length ?? 0}</ApprovalListTotal>
      )}
      <Button title="Approve All" onClick={approveAll} small />
      {pending?.length > 0 && (
        <ApprovalListItems>
          {pending.map((entry) => (
            <ApprovalListItem key={entry.uuid}>
              <div className="entry-title">
                <h3>{entry.name ?? "Anonymous"}</h3>
                <div className={`entry-status ${entry.answered}`}>
                  {entry.answered ? "answered" : "not answered"}
                </div>
              </div>
              <ul className="entry-metadata">
                <li>Submitted: {dayjs(entry.created_at).format("MMMM D, YYYY h:mm A")}</li>
                <li>Answered: {entry.answered ? dayjs(entry.date_answered).format("MMMM D, YYYY h:mm A") : "No"}</li>
                <li>Email: {entry.email}</li>
                <li>Phone: {entry.phone ? entry.phone : "N/A"}</li>
                <li>Preferred Contact Method: {capitalize(entry.contact_method)}</li>
              </ul>
              {entry.answered ? (
                <div>
                  <p className="entry-text">Original request: <em>{entry.prayer_request}</em></p>
                  <p className="entry-text">Updated response: <em>{entry.answered_prayer_description}</em></p>
                </div>
              ) : (
                <p className="entry-text"><em>{entry.prayer_request}</em></p>
              )}
              <ApprovalListItemButtonRow>
                <Button
                  title="Approve"
                  onClick={() => processRequest(entry.uuid, true)}
                  small
                />
                <Button
                  title="Reject"
                  onClick={() => processRequest(entry.uuid, false)}
                  small
                />
              </ApprovalListItemButtonRow>
            </ApprovalListItem>
          ))}
        </ApprovalListItems>
      )}
    </ApprovalListWrapper>
  );
};

export default ApprovalList;
