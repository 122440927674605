import formComponentFactory from "../../requestForm/formComponentFactory";
import { 
  ButtonWrapper,
  FormItemWrapper,
  FormInvalidWarning,
} from "../../requestForm/requestForm.styles";
import { formItems, THANK_YOU_MESSAGE } from "./config";
import {
  Description,
  FormWrapper,
  Wrapper,
  RequestHeader,
  RequestDate,
  RequestText,
  FormMessage,
  FormApprovalDisclaimer,
} from "./detailed.styles";
import Button from "../../button";
import { usePrayerRequestContext } from "../../../hooks/context/prayerRequest/prayerRequestContext";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Loader from "../../../components/loader";
import {
  Request
} from "../prayerRequest.styles";
import { PrayerWallEntry } from "../../../types";

type PrayerRequestDetailedProps = {
  entries: Array<any>;
  selectedEntry: PrayerWallEntry | null;
  setSearch?: any | null;
  setCurrentSelectedEntry?: (entry: PrayerWallEntry) => any | null;
}
const PrayerRequestDetailed: React.FC<PrayerRequestDetailedProps> = ({
  entries,
  selectedEntry,
  setSearch,
  setCurrentSelectedEntry
}) => {
  const history = useHistory();
  const params: any = useParams();
  const [showValidation, setShowValidation] = useState(false);

  const { 
    responses,
    validation,
    onInputChange,
    setThankYouMessage,
    markRequestAsAnswered,
  } = usePrayerRequestContext();

  useEffect(() => {
    params.uuid && setSearch(params.uuid);
  }, []);
  useEffect(() => {
    if (entries.length === 1 && entries[0]?.uuid === params?.uuid) {
      setCurrentSelectedEntry && setTimeout(() => setCurrentSelectedEntry(entries[0]), 250);
    };
  }, [entries]);

  const displayDate = (createdAt: any) => {
    let date = new Date(createdAt);
    let month = date.toLocaleString("default", { month: "long" });
    return `${month} ${date.getDate()}, ${date.getFullYear()}`;
  };

  const handleSubmitClick = async () => {
    setShowValidation(true);
    const result = await markRequestAsAnswered(params.uuid, responses.description);
    if (result?.success) {
      setThankYouMessage(THANK_YOU_MESSAGE);
      history.push("/requests");
    }
  };

  return (
    <Wrapper>
      <Description><b>We’re glad to hear your prayer was answered.</b></Description>
      <Description>
        Please take a minute to provide an update to your
        prayer request, so others can be encouraged by 
        God’s work in your life. 
      </Description>
      <Request style={{ 
        padding: 15,
        marginTop: 30,
        border: '1px solid #000',
      }}>
        {
          selectedEntry
            ? <div>
                <RequestHeader>
                  <h4>{selectedEntry.sharing_option === "everyone" ? selectedEntry.name : "Anonymous"}</h4>
                  <RequestDate>Received: {displayDate(selectedEntry.created_at)}</RequestDate>
                </RequestHeader>
                <RequestText>{selectedEntry.prayer_request}</RequestText>
                <hr style={{ color: '#000' }} />
                <RequestText>Prayed For: {selectedEntry.prayed_for_count} Times</RequestText>
              </div>
            : <Loader />
        }
      </Request>
      <FormWrapper>
        <FormMessage><b><i>Optional</i>: Please write how your prayer was answered</b></FormMessage>
        {formItems.map((config) => (
          <FormItemWrapper key={config.accessorKey}>
            {formComponentFactory(config, responses, onInputChange)}
            {showValidation &&
              config.requiresValidation &&
              !validation[config.accessorKey] && (
                <FormInvalidWarning>
                  {config.validationErrorMessage}
                </FormInvalidWarning>
              )}
          </FormItemWrapper>
        ))}
        <ButtonWrapper>
          <Button
            title="Mark as answered"
            onClick={handleSubmitClick}
          />
        </ButtonWrapper>
        <FormApprovalDisclaimer>
          Your update will be reviewed before it is posted on the Prayer Map.
        </FormApprovalDisclaimer>
      </FormWrapper>
    </Wrapper>
  );
};

export default PrayerRequestDetailed;