import formComponentFactory from "../formComponentFactory";
import { ButtonWrapper, FormItemWrapper } from "../requestForm.styles";
import { formItems, CONNECT_THANK_YOU_MESSAGE } from "./config";
import { REQUEST_THANK_YOU_MESSAGE } from "../formConfig";
import {
  Description,
  Dialogue,
  FormWrapper,
  Title,
  Wrapper,
} from "./connectChurchForm.styles";
import checkmarkIcon from "../../../media/checkmark-icon.svg";
import Button from "../../button";
import { usePrayerRequestContext } from "../../../hooks/context/prayerRequest/prayerRequestContext";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

const ConnectChurchForm: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const searchParams = location.search;
  const { responses, onInputChange, submitForm, setThankYouMessage } =
    usePrayerRequestContext();
  const [loading, setLoading] = useState<boolean>(false);
  useEffect(() => {
    responses.first_name === "" && history.replace("/submit-request");
  }, [history, responses.name]);

  const handleSubmitSeeker = async () => {
    setLoading(true);
    const result = await submitForm();
    setLoading(false);
    if (result?.success) {
      setThankYouMessage(CONNECT_THANK_YOU_MESSAGE);
      history.push(`/requests${searchParams}`);
    } else {
      setThankYouMessage(REQUEST_THANK_YOU_MESSAGE);
      history.push(`/requests${searchParams}`);
    };
  };

  const checkSubmitDisabled = () => {
    if (loading) {
      return true;
    }
    for (let config of formItems) {
      if (config.required) {
        return true;
      }
    }
    return false;
  };

  return (
    <Wrapper>
      <Dialogue className="text-xs">
        <img src={checkmarkIcon} alt="checkmark icon" />
        <p>Your prayer request has been sent for review.</p>
      </Dialogue>
      <Title>Connect To A Church</Title>
      <Description>
        We’re excited to see you would like to connect to a church. Please take
        a moment to provide a bit more information, and our team will reach out
        to you shortly.
      </Description>
      <FormWrapper>
        {formItems.map((config) => (
          <FormItemWrapper key={config.accessorKey}>
            {formComponentFactory(config, responses, onInputChange)}
          </FormItemWrapper>
        ))}
        <ButtonWrapper>
          <Button
            title={!loading ? "Submit Request" : "Loading..."}
            onClick={handleSubmitSeeker}
          />
        </ButtonWrapper>
      </FormWrapper>
    </Wrapper>
  );
};
export default ConnectChurchForm;
