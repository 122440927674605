import styled from "styled-components";
import { StyledButton } from "../../../components/button/button";
import { AdminItem } from "../admin.styles";

export const ApprovalListWrapper = styled(AdminItem)`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: auto auto auto;
  grid-template-areas:
    "title approve"
    "total approve"
    "requests requests";
  align-items: center;
  gap: ${({ theme }) => theme.utilities?.spacing?.["space-xs"]};

  .loader-wrapper {
    margin-top: ${({ theme }) => theme.utilities?.spacing?.["space-sm"]};
    width: fit-content;
    transform: scale(0.5);
    grid-area: total;
  }

  ${StyledButton} {
    grid-area: approve;
    width: 100%;
    font-family: inherit;
    font-weight: bold;
    width: 160px;
    background-color: rgb(85, 123, 212);
    color: #fff;
    border-radius: 0.25rem;
  }

  @media (max-width: 768px) {
    width: 90%;
    text-align: center;
    padding: ${({ theme }) => theme.utilities?.spacing?.["space-sm"]}
      ${({ theme }) => theme.utilities?.spacing?.["space-md"]};

    display: flex;
    flex-direction: column;
    align-items: center;
    .loader-wrapper {
      margin: ${({ theme }) => theme.utilities?.spacing?.["space-sm"]} 0;
    }
  }
`;

export const ApprovalListTitle = styled.p`
  font-size: 1.15rem;
  grid-area: title;
`;

export const ApprovalListTotal = styled.h2`
  grid-area: total;
`;

export const ApprovalListItems = styled.div`
  grid-area: requests;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${({ theme }) => theme.utilities?.spacing?.["space-md"]};
  margin-top: ${({ theme }) => theme.utilities?.spacing?.["space-sm"]};

  @media (max-width: 768px) {
    width: 100%;
    gap: ${({ theme }) => theme.utilities?.spacing?.["space-sm"]};
  }
`;

export const ApprovalListItem = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors?.["ui-background"]};
  border-radius: ${({ theme }) =>
    theme.utilities?.borders?.["border-radius-md"]};
  padding: ${({ theme }) => theme.utilities?.spacing?.["space-sm"]}
    ${({ theme }) => theme.utilities?.spacing?.["space-md"]};
  box-shadow: inset 0px 2px 4px 0px #bbbbbb40;

  display: grid;
  align-items: center;
  grid-template-columns: 1fr auto;
  grid-template-rows: auto auto;
  grid-template-areas:
    "title actions"
    "request actions";
  column-gap: ${({ theme }) => theme.utilities?.spacing?.["space-sm"]};

  .entry-title {
    display: flex;
    align-items: center;
  }

  .entry-status {
      width: fit-content;
      padding: 0 12px;
      height: 24px;
      border-radius: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 500;
      font-size: 0.85rem;
      color: gray;
      background-color: lightgray;
      margin-left: 10px;

      &.true {
        color: green;
        background-color: #00800020;
      }
    }

  .entry-metadata {
    font-size: 0.9rem;
    opacity: 0.7;
  }
  .entry-text {
    margin: 1rem 0 0;
  }

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;

    .entry-title {
      flex-direction: column;
    }
    .entry-status {
      margin-left: 0;
    }

    .entry-metadata {
      font-size: 0.8rem;
      text-align: left;
      margin-top: 1rem;
    }

    .entry-text {
      margin: 1rem 0;
      text-align: left;
      font-size: 0.9rem;
    }
  }
`;

export const ApprovalListItemButtonRow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${({ theme }) => theme.utilities?.spacing?.["space-sm"]};
  grid-area: actions;

  ${StyledButton} {
    border-radius: ${({ theme }) =>
      theme.utilities?.borders?.["border-radius-sm"]};
    width: 100%;
    font-size: 0.75rem;

    &:last-child {
      background-color: #b50000;
    }
  }

  @media (max-width: 768px) {
    gap: ${({ theme }) => theme.utilities?.spacing?.["space-xs"]};

    ${StyledButton} {
      width: 150px;
    }
  }
`;
