import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { SearchBarWrapper, SearchBarInput, SearchBarResetButton } from "./searchBar.styles";
import circleXIcon from "../../media/x-mark.svg";
import { PrayerWallEntry } from "../../types";

type SearchBarProps = {
  onSearch: (value: string) => void;
  onReset: () => void;
  selectedEntry: PrayerWallEntry | null;
};

const autoSearchInterval = 500;

const SearchBar: React.FC<SearchBarProps> = ({ onSearch, onReset, selectedEntry }) => {
  const [search, setSearch] = useState("");
  const [searchTimeout, setSearchTimeout] = useState<any>();

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let newVal = e.target.value;
    setSearch(newVal);

    clearTimeout(searchTimeout);
    setSearchTimeout(
      setTimeout(() => {
        onSearch(newVal);
      }, autoSearchInterval)
    );
  };

  const resetSearch = () => {
    setSearch("");
    onSearch("");
    onReset();
  }

  const showCloseButton = search.length || selectedEntry;

  return (
    <SearchBarWrapper>
      <SearchBarInput
        placeholder="Search..."
        value={search}
        onChange={onSearchChange}
      />
      <FontAwesomeIcon icon={faSearch} className="search-icon" />
      {showCloseButton && (
        <SearchBarResetButton onClick={resetSearch}>
          <img src={circleXIcon} />
        </SearchBarResetButton>
      )}
    </SearchBarWrapper>
  );
};

export default SearchBar;
