import styled from "styled-components";

export const PrayerWallWrapper = styled.div`
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: left;
  overflow: hidden;
  position: relative;
`;

export const EmptyText = styled.p`
  text-align: center;
  font-size: 1.25rem;
  opacity: 0.8;
`;

export const PrayerWallContentWrapper = styled.div`
  width: 100vw;
  height: 91vh;
  display: flex;
  flex-direction: row;
  position: relative;

  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

export const PrayerWallContentWrapperNota = styled.div`
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  overflow: hidden;
`;

export const PrayerWallLeftWrapper = styled.div`
  width: 65vw;
  height: 100%;
  display: flex;
  flex-direction: column;

  @media (min-width: 1440px) {
    width: 70vw;
  }
  @media (max-width: 1024px) {
    width: 100vw;
    height: 35vh;
  }
  @media (max-width: 400px) and (max-height: 800px) {
    width: 100vw;
    height: 35vh;
  }
  @media (max-width: 1024px) and (max-height: 512px) {
    width: 100vw;
    height: 100vh;
  }

  &.go-left-fs {
    width: 100vw;

    @media (max-width: 1024px) {
      height: 100vh;
    }
  }
`;

export const PrayerWallRightWrapper = styled.div`
  width: 35vw;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  background-color: #fff;
  position: relative;
  // position: static;

  .tab-manager {
    display: none;

    @media (max-width: 1024px) {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
      border: 1px solid #E3E3E3;
      border-radius: ${({ theme }) =>
        theme?.utilities?.borders?.["border-radius-md"]};
      margin: 2rem 2rem;
      background-color: #fff;
      font-size: 0.875rem;
      color: #000;

      * {
        flex: 1 1 50%;
        text-align: center;
        border-radius: ${({ theme }) =>
          theme?.utilities?.borders?.["border-radius-md"]};
        padding: 0.75rem ${({ theme }) => theme.utilities?.spacing?.["space-xs"]};
      }
      a:first-child {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
      a:last-child {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
      .active {
        background-color: #88D0FF;
        font-weight: 700;
      }
    }
  }

  @media (min-width: 1440px) {
    width: 30vw;
  }
  @media (max-width: 1024px) {
    width: 100vw;
    height: 56vh;
  }
  @media (max-width: 400px) and (max-height: 800px) {
    position: relative;
    width: 100vw;
    height: 56vh;
    background-color: #fff;
  }
  @media (max-width: 1024px) and (max-height: 512px) {
    display: block;
    position: absolute;
    z-index: 1;
    right: 0;
    width: 50%;
    height: 100vh;
    opacity: 0.86;
    -webkit-transition: all 500ms ease-in-out;
    -moz-transition: all 500ms ease-in-out;
    -o-transition: all 500ms ease-in-out;
    -ms-transition: all 500ms ease-in-out;
    transition: all 500ms ease-in-out;
    background-color: #000;

    &.closed {
      width: 0%;
    }
  }

  &.go-right-fs {
    width: 0;

    @media (max-width: 1024px) {
      height: 0;
    }
  }
`;

export const PrayerWallFooterWrapper = styled.div`
  width: 100%;
  height: 20vh;
  background-color: #F7F7F7;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  padding: 0 0;

  .presentation {
    width: 10rem;

    @media (min-width: 1578px) {
      width: 12rem;
    }
  }
  .logo-1 {
    width: 10rem;
    height: 90px;
    background-color: #fff;
    border: 1px solid #D8D8D8;
    border-radius: ${({ theme }) =>
      theme?.utilities?.borders?.["border-radius-md"]};
    padding: 15px 35px;

    @media (min-width: 1578px) {
      width: 12rem;
    }
  }
  .logo-2 {
    width: 9rem;
    height: 90px;
    background-color: #fff;
    border: 1px solid #D8D8D8;
    border-radius: ${({ theme }) =>
      theme?.utilities?.borders?.["border-radius-md"]};
    padding: 15px 15px;

    @media (min-width: 1578px) {
      width: 10rem;
    }
  }
  .between-logos {
    margin: 0 -6rem;

    @media (max-width: 1200px) {
      margin: 0 -5rem;
    }
    @media (max-width: 1100px) {
      margin: 0 -4rem;
    }
    @media (min-width: 1578px) {
      margin: 0 -10rem;
    }
  }

  @media (min-width: 1440px) {
    padding: 0 ${({ theme }) => theme.utilities?.spacing?.["space-lg"]};
  }
  @media (max-width: 1024px) {
    display: none;
  }

  &.go-foot-fs {
    display: none;
  }
`;

///////////////////////// RIGHT COMPONENTS

export const PWSubmitRequest = styled.div`
  width: 90%;
  background-color: #F7F7F7;
  border-radius: ${({ theme }) =>
    theme?.utilities?.borders?.["border-radius-md"]};
  margin: auto;
  margin-top: 1.5rem;
  z-index: 100;
`;

export const HeaderForm = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row-reverse;

  @media (max-width: 1024px) {
    width: 100%;
    scroll-snap-align: start;
    justify-content: center;
  }
`;

export const PWViewRequests = styled.div`
  width: 35vw;
  background-color: #F7F7F7;
  position: absolute;

  @media (min-width: 1440px) {
    width: 30vw;
  }

  @media (max-width: 1024px) {
    width: 100%;
    position: relative;
    background-color: #fff;
  }

  @media (max-width: 1024px) and (max-height: 768px) {
    //background-color: #000;
  }
`;

export const PrayerWallAsideContent = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  padding: ${({ theme }) => theme.utilities?.spacing?.["space-sm"]};
  gap: ${({ theme }) => theme.utilities?.spacing?.["space-md"]};
`;

export const ThankYouCard = styled.div`
  width: 100%;
  padding: calc(${({ theme }) => theme.utilities?.spacing?.["space-sm"]} * 1.5)
    calc(${({ theme }) => theme.utilities?.spacing?.["space-sm"]} * 1.5);
  border-radius: ${({ theme }) =>
    theme.utilities?.borders?.["border-radius-md"]};
  color: #000;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.utilities?.spacing?.["space-xs"]};
  position: relative;
  z-index: 20;
  transition: ease 0.2s;
  background-color: #fff;
`;

export const ThankYouHeader = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: ${({ theme }) => theme.utilities?.spacing?.["space-sm"]};

  h3 {
    float: left;
  }
`;

export const CloseThankYouCard = styled.div`
  font-size: 0.75rem;
  align-self: center;
  margin-left: auto;
  cursor: pointer;
`;

export const ThankYouText = styled.p`
a {
  text-decoration: underline;
}
`;
