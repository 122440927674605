export type InputConfigType =
  | "input"
  | "radio"
  | "dropdown"
  | "textarea"
  | "checkbox"
  | "radiobox"
  | "row";

export type FormInputConfig = {
  name?: string;
  placeholder?: string;
  accessorKey: string;
  required: boolean;
  requiresValidation: boolean;
  type: InputConfigType;
  options?: string[][];
  validationErrorMessage?: string;
  rowChildren?: FormInputConfig[]
};

export const formItems: FormInputConfig[] = [
  {
    name: "Your prayer request *",
    placeholder: "Please write your prayer request here",
    accessorKey: "prayer_request",
    required: true,
    requiresValidation: true,
    type: "textarea",
    validationErrorMessage: "Please enter a prayer request.",
  },
  // {
  //   name: "Name",
  //   accessorKey: "name",
  //   required: true,
  //   requiresValidation: true,
  //   type: "row",
  //   rowChildren: [
  //     {
  //       name: "First name *",
  //       placeholder: "John",
  //       accessorKey: "first_name",
  //       required: true,
  //       requiresValidation: true,
  //       type: "input",
  //     },
  //     {
  //       name: "Last name *",
  //       placeholder: "Doe",
  //       accessorKey: "last_name",
  //       required: true,
  //       requiresValidation: true,
  //       type: "input",
  //     }
  //   ],
  // },
  {
    name: "First name *",
    accessorKey: "first_name",
    required: true,
    requiresValidation: true,
    type: "input",
    validationErrorMessage: "Please enter your name.",
  },
  {
    name: "Email *",
    placeholder: "johndoe@example.com",
    accessorKey: "email",
    required: true,
    requiresValidation: true,
    type: "input",
    validationErrorMessage: "Please enter a valid email address.",
  },
  // {
  //   name: "Phone *",
  //   placeholder: "+1234567890",
  //   accessorKey: "phone",
  //   required: true,
  //   requiresValidation: true,
  //   type: "input",
  //   validationErrorMessage: "Please enter a valid phone number.",
  // },
  {
    name: "Sharing preference *",
    accessorKey: "sharing_option",
    required: true,
    requiresValidation: false,
    type: "dropdown",
    options: [
      ["everyone", "Share with everyone"],
      ["anonymous", "Share anonymously"],
    ],
  },
  // {
  //   name: "Are you actively following Jesus? *",
  //   accessorKey: "discipleship_status",
  //   required: true,
  //   requiresValidation: true,
  //   type: "radio",
  //   options: [
  //     ["new", "I want to follow Jesus."],
  //     ["returning", "I used to follow Jesus and want to return."],
  //     ["current", "I am currently following Jesus, and I want resources to share Him."],
  //     ["nointerest", "Right now, I only need prayer."],
  //   ],
  //   validationErrorMessage: "Please select an option.",
  // },
  // {
  //   placeholder: "Connect me to local church.",
  //   accessorKey: "connect_local_church",
  //   required: false,
  //   requiresValidation: false,
  //   type: "checkbox",
  // },
  {
    placeholder: "Email when someone prays for me.",
    accessorKey: "send_email_updates",
    required: false,
    requiresValidation: false,
    type: "checkbox",
  },
  {
    placeholder: "Send me weekly encouragement.",
    accessorKey: "send_weekly_encouragement",
    required: false,
    requiresValidation: false,
    type: "checkbox",
  },
];

export const REQUEST_THANK_YOU_MESSAGE =
  "Your request has been submitted and will be reviewed shortly. In the meantime, why not pray for some other requests?";