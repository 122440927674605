import useAuth from "../../hooks/useAuth";
import {
  AdminHeader,
  AdminHeaderLogo,
  AdminPage,
  AdminTitleRow,
  RefreshButton,
} from "./admin.styles";
import Login from "./login";
import ApprovalList from "./approvalList";
import EncouragementList from "./encouragementList";
import { faSyncAlt } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

const s3 = process.env.REACT_APP_S3_URL;

const Admin = () => {
  const [refresh, setRefresh] = useState(false);
  const { currentUser, login, loginError, logout } = useAuth();
  const history = useHistory();

  useEffect(() => {
    if (refresh) {
      setRefresh(false);
    }
  }, [refresh]);

  return (
    <AdminPage>
      <AdminHeader>
        <div onClick={() => history.push('/')}>
          <AdminHeaderLogo src={s3 + "/logo.svg"} alt="Prayer Request"/>
        </div>
        {currentUser && (
          <h4 onClick={logout}>
            Logged in as {currentUser.name.first} (<span>Log Out</span>)
          </h4>
        )}
      </AdminHeader>
      {!!currentUser ? (
        <>
          <AdminTitleRow>
            <h2>Admin Console</h2>
            <RefreshButton
              icon={faSyncAlt}
              size="lg"
              onClick={() => setRefresh(true)}
            />
          </AdminTitleRow>
          <EncouragementList refresh={refresh} />
          <ApprovalList refresh={refresh} />
        </>
      ) : (
        <Login onLogin={login} loginError={loginError} />
      )}
    </AdminPage>
  );
};

export default Admin;
