import { FacebookShareButton, TwitterShareButton } from "react-share";
import { PrayerWallEntry } from "../../../types";
import { RequestActionRow, RequestAnswered, RequestPrayedForCount, RequestText, RequestTextExpand, RequestThankYou } from "../prayerRequest.styles";
import {
  CreatedAt,
  ProfileClose,
  ProfileHeader,
  ProfileHeaderText,
  PrayerWallProfile,
  ProfileInfo,
  CopyUrlButton,
} from "./popout.styles";

import FbIcon from '../../../media/facebook.svg';
import TwIcon from '../../../media/twitter.svg';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faCopy, faPrayingHands } from "@fortawesome/free-solid-svg-icons";
import Button from "../../button";
import { useState} from "react";

export type RequestPopoutProps = {
  selectedEntry: PrayerWallEntry;
  incrementPrayedForCount: (entry: PrayerWallEntry) => any;
  leaving: boolean;
  handleLeave: () => void;
  isNota:boolean;
  isNotaSideBar?:boolean;
};

const trimRequestText = (text: string, length = 150) => {
    if (text.length < length) return text;

    return text.substr(0, length) + "...";
  };

const RequestPopout: React.FC<RequestPopoutProps> = ({
  selectedEntry,
  incrementPrayedForCount,
  leaving,
  handleLeave,
  isNota,
  isNotaSideBar
}) => {
    const [isCollapsed, setIsCollapsed] = useState(true);
    const [copied, setCopied] = useState(false);

    const shareURL = "pray.blessedmedia.co";

    let requestText = isCollapsed
      ? trimRequestText(selectedEntry.prayer_request)
      : selectedEntry.prayer_request;

    const isCollapsible = selectedEntry.prayer_request.length > 150;

    const [showOriginalPrayer, setShowOriginalPrayer] = useState(false);
    const handleShowOriginalPrayer = () => setShowOriginalPrayer(true);

    const handleCopyClick = () => {
      navigator.clipboard.writeText(`${window.location.origin}/requests/${selectedEntry.uuid}`);
      setCopied(true);
      setTimeout(() => setCopied(false), 3000);
    };
    const NotaPrayerWallProfile = () =>
        <PrayerWallProfile
            className={`${leaving && "leaving"} notaApp ${isNotaSideBar && "notaSideBar"}`}
        >
          {!isNotaSideBar && <ProfileClose className={`notaApp`} style={{ zIndex: 1 }} onClick={handleLeave}>
            X
          </ProfileClose>}
          <ProfileInfo className={`notaApp`}>
            <h1>
              {selectedEntry.sharing_option === "everyone" ? selectedEntry.name : "Anonymous"}
            </h1>
            <div className={'prayed-for-created-at'}>
              <RequestPrayedForCount className={'notaApp'}>
                Prayed For: {selectedEntry.prayed_for_count} Times
              </RequestPrayedForCount>
              <CreatedAt>{formatCreatedAt(selectedEntry.created_at)}</CreatedAt>
            </div>
            {(selectedEntry.answered && !showOriginalPrayer) && <small onClick={handleShowOriginalPrayer}>Click to see original prayer</small>}
            {(selectedEntry.answered && showOriginalPrayer) && <RequestText>{selectedEntry.answered_prayer_description}</RequestText>}
            <RequestText
                className={`${isCollapsible && "collapsible"} notaApp`}
                onClick={() => setIsCollapsed(!isCollapsed)}
            >
              "{requestText}"
              {isCollapsed && isCollapsible && (
                  <RequestTextExpand>Click to expand</RequestTextExpand>
              )}
              {!isCollapsed && isCollapsible && (
                  <RequestTextExpand>Click to collapse</RequestTextExpand>
              )}
            </RequestText>
          </ProfileInfo>
          <RequestActionRow className={'notaApp'}>
            <div className={'notaApp share-buttons-nota-container'}>
              <div className={'notaApp share-buttons-nota'}>
                <TwitterShareButton
                    url={shareURL}
                    title={`Join me in prayer for ${selectedEntry.name}! `}
                    className="social-button twitter notaApp"
                >
                  <img src={TwIcon} style={{ width: '20px', margin: '5px' }} alt="Twitter icon" />
                </TwitterShareButton>
                <FacebookShareButton
                    url={shareURL}
                    quote={`I just prayed for ${selectedEntry.name}. Come join me in prayer!`}
                    className="social-button facebook notaApp"
                >
                  <img src={FbIcon} style={{ width: '20px' }} alt="Facebook icon" />
                </FacebookShareButton>
                <CopyUrlButton onClick={handleCopyClick} className={'notaApp'}>
                  {copied ? <span>Copied!</span> : <FontAwesomeIcon icon={faCopy} />}
                </CopyUrlButton>
              </div>
            </div>
            <div className={'notaApp prayer-button-nota'}>
              {selectedEntry.answered ?
                  <RequestAnswered className={'notaApp'}><FontAwesomeIcon icon={faCheck} /> Answered</RequestAnswered> :
                  (selectedEntry.has_prayed_for ? (
                      <RequestThankYou className={'notaApp'}>Thank you for praying.</RequestThankYou>
                  ) : (
                      <Button
                          title="PRAY"
                          onClick={() => incrementPrayedForCount(selectedEntry)}
                      >
                        <FontAwesomeIcon icon={faPrayingHands} />
                      </Button>
                  ))}
            </div>
          </RequestActionRow>
        </PrayerWallProfile>


    if (isNota) {
      // Is the nota app
      return (
          <NotaPrayerWallProfile/>
      );
    } else {
      // Is NOT the nota app
      return (
          <PrayerWallProfile
              className={`${leaving && "leaving"}`}
          >
            <ProfileClose style={{ zIndex: 1 }} onClick={handleLeave}>
              Close
            </ProfileClose>
            <ProfileHeader>
              <ProfileHeaderText>Prayer Request</ProfileHeaderText>
            </ProfileHeader>
            <ProfileInfo>
              <h3>
                {selectedEntry.sharing_option === "everyone" ? selectedEntry.name : "Anonymous"}
              </h3>
              {(selectedEntry.answered && !showOriginalPrayer) && <small onClick={handleShowOriginalPrayer}>Click to see original prayer</small>}
              {(selectedEntry.answered && showOriginalPrayer) && <RequestText>{selectedEntry.answered_prayer_description}</RequestText>}
              <RequestText
                  className={isCollapsible ? "collapsible" : ""}
                  onClick={() => setIsCollapsed(!isCollapsed)}
              >
                {requestText}
                {isCollapsed && isCollapsible && (
                    <RequestTextExpand>Click to expand</RequestTextExpand>
                )}
                {!isCollapsed && isCollapsible && (
                    <RequestTextExpand>Click to collapse</RequestTextExpand>
                )}
              </RequestText>
              <CreatedAt>{formatCreatedAt(selectedEntry.created_at)}</CreatedAt>
            </ProfileInfo>
            <RequestActionRow>
              <div>
                <RequestPrayedForCount>
                  Prayed For: {selectedEntry.prayed_for_count} Times
                </RequestPrayedForCount>
                <div>
                  <p>Share on Social Media:&nbsp;</p>
                  <TwitterShareButton
                      url={shareURL}
                      title={`Join me in prayer for ${selectedEntry.name}! `}
                      className="social-button twitter"
                  >
                    <img src={TwIcon} style={{ width: '20px', margin: '5px' }} alt="Twitter icon" />
                  </TwitterShareButton>
                  <FacebookShareButton
                      url={shareURL}
                      quote={`I just prayed for ${selectedEntry.name}. Come join me in prayer!`}
                      className="social-button facebook"
                  >
                    <img src={FbIcon} style={{ width: '20px' }} alt="Facebook icon" />
                  </FacebookShareButton>
                  <CopyUrlButton onClick={handleCopyClick}>
                    {copied ? <span>Copied!</span> : <FontAwesomeIcon icon={faCopy} />}
                  </CopyUrlButton>
                </div>
              </div>
              <div>
                {selectedEntry.answered ?
                    <RequestAnswered><FontAwesomeIcon icon={faCheck} /> Answered</RequestAnswered> :
                    (selectedEntry.has_prayed_for ? (
                        <RequestThankYou>Thank you for praying.</RequestThankYou>
                    ) : (
                        <Button
                            title="I prayed for this"
                            onClick={() => incrementPrayedForCount(selectedEntry)}
                        >
                          <FontAwesomeIcon icon={faPrayingHands} />
                        </Button>
                    ))}
              </div>
            </RequestActionRow>
          </PrayerWallProfile>
      );
    }

};

const formatCreatedAt = (createdAt: string) => {
  let date = new Date(createdAt);

  let month = date.toLocaleString("default", { month: "long" });

  return `Received: ${month} ${date.getUTCDate()}, ${date.getFullYear()}`;
};

export default RequestPopout;
