import { useState } from "react";
import serverClient from "../api/serverClient";
import {
  PrayerWallEntry,
  FormResponses,
  SeekerResponse,
  RequestAnswerResponse,
} from "../types";
import useFormValidation from "./useFormValidation";
import { Geolocation } from "./useGeolocation";

const defaultFormResponses: FormResponses = {
  name: "",
  first_name: "",
  last_name: "",
  email: "",
  phone: "",
  gender: "male",
  contact_method: "email",
  sharing_option: "everyone",
  prayer_request: "",
  discipleship_status: "",
  connect_local_church: false,
  send_email_updates: true,
  send_weekly_encouragement: true,
  wants_to_connect: false,
  already_connected: false,
  influencer: null,
};

const useRequestForm = (geolocation?: Geolocation) => {
  const [responses, setResponses] =
    useState<FormResponses>(defaultFormResponses);
  const [newlyCreatedEntry, setNewlyCreatedEntry] =
    useState<PrayerWallEntry | null>(null);
  // const clearForm = () => setResponses(defaultFormResponses);

  let origin = new URL(window.location.href).hostname;
  const searchParams = new URLSearchParams(document.location.search);
  const influencer = searchParams.get("influencer");
  let search_params: Record<string, string | boolean> = {};
  searchParams.forEach((value, key) => {
    if (key === "nota") {
      search_params[key] = value === "true"; // Convert the value to a boolean if the key is 'nota'
    } else {
      search_params[key] = value;
    }
  });

  const { validation, checkValidation } = useFormValidation();

  const onInputChange = (key: string, value: any) => {
    setResponses({
      ...responses,
      [key]: value,
    });

    checkValidation(key, value);
  };

  const generateNewEntry = async (): Promise<PrayerWallEntry> => {
    const {
      connect_local_church,
      gender,
      preferred_contact_method,
      ...newEntryFields
    } = responses;
    let submitObj: { [key: string]: any } = {
      ...newEntryFields,
      coords: geolocation
        ? [geolocation.latitude, geolocation.longitude]
        : undefined,
      country: geolocation?.countryCode.toLowerCase(),
      wants_to_connect: connect_local_church,
      already_connected: connect_local_church,
      origin,
      name: (responses.first_name + " " + responses.last_name).trim(),
      influencer,
      search_params:
        Object.keys(search_params).length === 0 ? null : search_params,
    };

    for (let [key, val] of Object.entries(submitObj)) {
      if (val === "") {
        submitObj[key] = null;
      }
    }

    const result = await serverClient.createPrayerWallEntry(submitObj);
    setNewlyCreatedEntry(result);
    return result;
  };

  const submitForm: () => Promise<SeekerResponse> = async () => {
    type ContactMethodMapper = { [key: string]: string };
    const contactMethodMapper: ContactMethodMapper = {
      email: "Email",
      sms: "SMS",
      whatsapp: "WhatsApp",
    };
    const updateObj = {
      // ...responses,
      preferred_contact_method:
        contactMethodMapper[
          responses.preferred_contact_method as keyof ContactMethodMapper
        ],
      gender: responses.gender,
    };

    const result = await serverClient.updateSeeker(responses.email, updateObj);
    console.log("submitForm:", { result });

    if (
      result &&
      newlyCreatedEntry?.contact_method !== responses.preferred_contact_method
    ) {
      await serverClient.updatePrayerWallEntry(newlyCreatedEntry?.uuid, {
        contact_method: responses.preferred_contact_method,
      });
    }
    return result;
  };

  const markRequestAsAnswered: (
    uuid: string,
    description?: string
  ) => Promise<RequestAnswerResponse> = async (uuid, description) => {
    const result = await serverClient.markPrayerRequestAsAnswered(
      uuid,
      description
    );
    return result;
  };

  return {
    responses,
    validation,
    onInputChange,
    generateNewEntry,
    submitForm,
    markRequestAsAnswered,
  };
};

export default useRequestForm;
