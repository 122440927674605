import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

export const AdminPage = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${({ theme }) => theme.utilities?.spacing?.["space-lg"]};
  padding-bottom: ${({ theme }) => theme.utilities?.spacing?.["space-lg"]};

  @media (max-width: 768px) {
    gap: ${({ theme }) => theme.utilities?.spacing?.["space-md"]};
    padding-bottom: ${({ theme }) => theme.utilities?.spacing?.["space-md"]};
  }
`;

export const AdminHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: ${({ theme }) => theme.colors?.["primary-dark"]};
  padding: ${({ theme }) => theme.utilities?.spacing?.["space-md"]}
    ${({ theme }) => theme.utilities?.spacing?.["space-page"]};
  h4 {
    color: white;
    font-weight: normal;

    span {
      cursor: pointer;
      transition: 0.2s;
      font-weight: bold;

      &:hover {
        filter: brightness(0.8);
      }
    }
  }

  @media (max-width: 768px) {
    padding: ${({ theme }) => theme.utilities?.spacing?.["space-md"]};
    gap: ${({ theme }) => theme.utilities?.spacing?.["space-sm"]};
    flex-direction: column;
    justify-content: center;

    text-align: center;
  }
`;

export const AdminHeaderLogo = styled.img`
  width: 275px;
  cursor: pointer;
  @media (max-width: 768px) {
    width: 100%;
    max-width: 275px;
  }
`;

export const AdminItem = styled.div`
  width: 800px;
  padding: calc(${({ theme }) => theme.utilities?.spacing?.["space-sm"]} * 1.5)
    calc(${({ theme }) => theme.utilities?.spacing?.["space-md"]} * 1.5);
  border-radius: ${({ theme }) =>
    theme.utilities?.borders?.["border-radius-lg"]};
  box-shadow: 0px 4px 8px 0px #bbbbbb40;
  background-color: ${({ theme }) => theme.colors?.["ui-light"]};

  h3 {
    color: ${({ theme }) => theme.colors?.["text-default"]};
  }

  @media (max-width: 768px) {
    width: 90%;
  }
`;

export const AdminTitleRow = styled.div`
  width: 600px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }) => theme.colors?.["text-default"]}40;
  padding: 0 ${({ theme }) => theme.utilities?.spacing?.["space-sm"]}
    ${({ theme }) => theme.utilities?.spacing?.["space-xs"]};

  @media (max-width: 768px) {
    width: 80%;
  }
`;

export const RefreshButton = styled(FontAwesomeIcon)`
  cursor: pointer;

  &:hover {
    filter: brightness(0.8);
  }
`;
