import { useState } from "react";
//@ts-ignore
import { FlyToInterpolator, WebMercatorViewport } from "deck.gl";
import bbox from "@turf/bbox";
import CountryService from "../../../services/country-service";
import {
  CountryClickFunction,
  GetCountryFillColorFunction,
  ItemHoverFunction,
  JSONCountryRow,
  MapItemInfo,
} from "../types";
import useCountryLayer from "./useCountryLayer";


type SelectedItem = {
  geo_lat: string;
  geo_long: string;
  coords: number[];
};

type MapControls = {
  viewState: Partial<any>;
  setViewState: (viewState: Partial<any>) => void;
  userViewState: Partial<any>;
  setUserViewState: (viewState: Partial<any>) => void;
  moveToSelectedItem: (item: SelectedItem) => void;
  moveToCountry: (name: string) => void;
  onItemHover: ItemHoverFunction;
  onCountryClick: CountryClickFunction;
  getCountryFillColor: GetCountryFillColorFunction;
  countryLayer: any;
};

const defaultViewState = {
  minZoom: window.matchMedia("(max-width: 1024px)").matches ? 1 : 1.75,
  latitude: 25,
  longitude: 0,
  zoom: window.matchMedia("(max-width: 1024px)").matches ? 1 : 1.75,
  width: window.innerWidth,
  height: window.innerHeight,
};

type MapControlsHook = (
  highlightedCountry: string,
  setHighlightedCountry: (country: string) => void,
  selectedCountry: string,
  setSelectedCountry: (country: string) => void,
  canSelectCountry: boolean
) => MapControls;

const useMapControls: MapControlsHook = (
  highlightedCountry,
  setHighlightedCountry,
  selectedCountry,
  setSelectedCountry,
  canSelectCountry
) => {
  const [viewState, setViewState] = useState<any>(defaultViewState);

  const [userViewState, setUserViewState] =
    useState<any>(defaultViewState);

  const moveToSelectedItem = ({ geo_lat, geo_long, coords }: SelectedItem) => {
    if (coords) {
      setViewState({
        ...viewState,
        pitch: 60,
        longitude: coords[0],
        latitude: coords[1],
        zoom: 9,
        transitionInterpolator: new FlyToInterpolator(),
        transitionDuration: 1500,
      });
    } else {
      setViewState({
        ...viewState,
        pitch: 60,
        longitude: Number(geo_long),
        latitude: Number(geo_lat),
        zoom: 9,
        transitionInterpolator: new FlyToInterpolator(),
        transitionDuration: 1500,
      });
    }
  };

  const moveToCountry = (name: string) => {
    const [minLng, minLat, maxLng, maxLat] = bbox(
      CountryService.getCountryBounds(name)
    );
    let { longitude, latitude, zoom } = new WebMercatorViewport(
      viewState
    ).fitBounds([
      [minLng, minLat],
      [maxLng, maxLat],
    ]);

    if (name === "United States") {
      // On map load, show just continental US
      longitude = -100;
      latitude = 38;
      zoom = 3;
    }

    setViewState({
      pitch: 60,
      longitude,
      latitude,
      zoom,
      transitionInterpolator: new FlyToInterpolator(),
      transitionDuration: 750,
    });
  };

  const onItemHover = (object: any, itemType: string) => {
    if (object) {
      if (itemType === "country") {
        setHighlightedCountry(object.properties.ADMIN);
      } else if (itemType !== "hex") {
        setHighlightedCountry(object.country);
      } else {
        setHighlightedCountry(object.points?.[0]?.source.country);
      }
    } else {
      setHighlightedCountry("");
    }
  };

  const onCountryClick = (info: MapItemInfo) => {
    if (info.index > 0) {
      //@ts-ignore
      let country = CountryService.geoJSON.features[
        info.index
      ] as unknown as JSONCountryRow;
      if (country.properties.ADMIN === selectedCountry) {
        setSelectedCountry("");
      } else {
        setSelectedCountry(country.properties.ADMIN);
      }
    } else {
      setSelectedCountry("");
    }
  };

  const getCountryFillColor = (country: JSONCountryRow) => {
    let name = country.properties.ADMIN;
    if (
      (canSelectCountry && selectedCountry === name) ||
      highlightedCountry === name
    ) {
      return [41, 87, 204, 51];
    } else {
      return [0, 0, 0, 0];
    }
  };

  const countryLayer = useCountryLayer(
    selectedCountry,
    highlightedCountry,
    canSelectCountry,
    getCountryFillColor,
    onItemHover,
    onCountryClick
  );

  return {
    viewState,
    setViewState,
    userViewState,
    setUserViewState,
    moveToSelectedItem,
    moveToCountry,
    onItemHover,
    onCountryClick,
    getCountryFillColor,
    countryLayer,
  };
};

export default useMapControls;
