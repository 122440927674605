import styled from "styled-components";

type ButtonProps = {
  title: string;
  disabled?: boolean;
  onClick: () => void;
  small?: boolean;
};

export const StyledButton = styled.button`
  background-color: #fff;
  padding: ${({ theme }) => theme.utilities?.spacing?.["space-sm"]}
    ${({ theme }) => theme.utilities?.spacing?.["space-md"]};
  text-transform: uppercase;
  letter-spacing: 0.01em;
  cursor: pointer;
  color: #000;
  border-style: none;
  width: fit-content;
  border-radius: ${({ theme }) =>
    theme.utilities?.borders?.["border-radius-md"]};
  font-weight: 700;
  font-size: 1rem;
  transition: 0.2s;

  &:hover {
    filter: brightness(0.8);
  }

  &.disabled {
    // filter: brightness(0.5);
    cursor: not-allowed;
  }

  &.small {
    font-size: 0.85rem;
    border-radius: ${({ theme }) =>
      theme.utilities?.borders?.["border-radius-md"]};
    padding: ${({ theme }) => theme.utilities?.spacing?.["space-xs"]}
      ${({ theme }) => theme.utilities?.spacing?.["space-sm"]};
  }

  &.blue {
    background-color: rgb(85, 123, 212);
    color: #fff;
  }

  @media (max-width: 1024px) {
    font-size: 0.75rem;
    border-radius: ${({ theme }) =>
      theme.utilities?.borders?.["border-radius-md"]};
    padding: ${({ theme }) => theme.utilities?.spacing?.["space-xs"]}
      ${({ theme }) => theme.utilities?.spacing?.["space-sm"]};
  }
`;

const Button: React.FC<ButtonProps> = ({
  title,
  onClick,
  disabled,
  small,
  children,
}) => {
  const onButtonClick = () => {
    if (!disabled) {
      onClick();
    }
  };

  const disabledClassName = disabled ? "disabled" : "";
  const smallClassName = small ? "small" : "";

  return (
    <StyledButton
      onClick={onButtonClick}
      className={`${disabledClassName} ${smallClassName}`}
    >
      {title}&nbsp;&nbsp;{children}
    </StyledButton>
  );
};

export default Button;
