import styled from "styled-components";

export const AppBarWrapper = styled.div`
	width: 100vw;
	height: 9vh;
	background-color: #000;
	display: flex;
	align-items: center;

	@media (max-width: 1024px) and (max-height: 512px) {
    display: block;
		position: absolute;
		z-index: 10;
		width: 25vw;
		left: 0;
		top: 0;
		background-color: transparent;
  }

	@media (max-width: 400px) and (max-height: 800px) {
		width: 100vw;
		height: 9vh;
		background-color: #000;
		display: flex;
		align-items: center;
		position: relative;
	}
`;

export const AppBarLogo = styled.img`
  width: 260px;
	margin: 0.5rem 4rem;
	float: left;

  @media (max-width: 1024px) {
    display: block;
		margin: auto;
  }

	@media (max-height: 768px) {
    width: 135px;
  }

  @media (max-width: 1024px) and (max-height: 512px) {
    margin: 0.5rem 0.5rem;
  }

	@media (max-width: 400px) and (max-height: 800px) {
		width: 125px;
		margin: auto;
	}
`;

export const AppBarMenu = styled.div`
	color: #88d0ff;
	height: 100%;
	margin: auto;
	margin-right: 3rem;
	font-size: 0.9rem;

	ul {
		height: 100%;
		display: flex;
		list-style: none;

		li {
			height: 100%;
			padding: 1.2rem 1rem;

			@media (min-width: 1770px) {
				padding: 2rem 1rem;
			}
		}
		.active {
			border-bottom: 5px solid #88d0ff;
		}
		li:hover {
			cursor: pointer;
			filter: brightness(0.8);
		}
	}

	@media (max-width: 1024px) {
    display: none;
  }
`;
