/**
 * Makes a request to the API
 * @param url The request url
 * @param path The api path (without url)
 * @param method The request method
 * @param params Parameters to include with the request
 * @returns The API's response
 */
const makeRequest = async (
  url: string,
  path: string,
  method: string,
  params: any = null
) => {
  return new Promise(async (resolve, reject) => {
    method = method.toUpperCase();
    let endpoint = url + path;
    let requestOpts = {};

    if ((method === "GET" || method === "DELETE") && params) {
      endpoint = endpoint + generateQueryParams(params);
    }
    if (method === "POST" || method === "PUT") {
      requestOpts = {
        method,
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(params),
      };
    }

    let response = await fetch(endpoint, requestOpts).catch(() => null);
    if (response?.status! >= 200 && response?.status! < 300) {
      let result = response?.json().catch(() => null);
      resolve(result);
    } else {
      resolve(null);
    }
  });
};

const generateQueryParams = (params: any) => {
  let queryString = "?";
  Object.keys(params).forEach((key) => {
    queryString += `${key}=${params[key]}&`;
  });
  return queryString.slice(0, -1);
};

export default makeRequest;
