import MapSidebar from "../mapSidebar";
import {
  ArcMapWrapper,
  MapKey,
  MapKeyNota,
  MapKeyNotaDecoration,
} from "./styles";
import InteractiveMap from "../interactive-map";
import React, { useEffect, useState } from "react";
import useArcMapLayers from "./hooks/useArcMapLayers";
import useArcMapFilters from "./hooks/useArcMapFilters";
import useArcMapData, { Filters } from "./hooks/useArcMapData";
import { PrayerWallEntry } from "../../types";
import { AnsweredStatus, MapView } from "../mapSidebar/mapSidebarFilters/mapSidebarFilters";
import NotaMapSidebar from "../mapSidebar/notaMapSidebar";

type ArcMapProps = {
  title: string;
  filters: Filters;
  loading: boolean;
  selectedEntry: PrayerWallEntry | null;
  handleSelectEntry: (entry: PrayerWallEntry) => void;
  isNota: boolean;
  handleLeave: () => void;
};

const ArcMap: React.FC<ArcMapProps> = ({
  title,
  filters: inheritedFilters,
  loading,
  selectedEntry,
  handleSelectEntry,
  isNota,
  handleLeave,
}) => {
  const [filteredArcs, setFilteredArcs] = useState([]);
  const [arcAnimationCoef, setArcAnimationCoef] = useState(0);
  const [mapViewFilter, setMapViewFilter] = useState<MapView>("world");
  const [answeredStatusFilter, setAnsweredStatusFilter] = useState<AnsweredStatus>("all");

  const [selectedCountry, setSelectedCountry] = useState<string | null>(null);

  const [arcMapHookFilters, setArcMapHookFilters] = useState<Filters>({ answeredStatus: [answeredStatusFilter] });

  const filters = useArcMapFilters();
  const mapData = useArcMapData(filters, arcMapHookFilters, loading);
  const [iconSize, setIconSize] = useState(5)

  const [filtersIsOpen, setFiltersIsOpen] = useState(false);
  const [listIsOpen, setListIsOpen] = useState(false);
  const [topSliceOfMapData, setTopSliceOfMapData] = useState<number>(100)
  const renderTooltip = ({ object }: any) => {
    if (!object || !object.uuid) {
      return null;
    }
    const { name, sharing_option } = object;

    const nameToBeShown =
    sharing_option === "everyone" && name
      ? name
      : "Anonymous";

    return {
      html: `<div class="profile-tooltip">
               <h5>${nameToBeShown}</h5>
             </div>`,
      style: {
        backgroundColor: "transparent",
      },
    };
  };
  useEffect(() => {
    if (listIsOpen) {
      handleLeave()
    }
  } , [listIsOpen])
  useEffect(() => {
    if (selectedEntry) {
      let filtered = mapData.data.arcs.filter(
        //@ts-ignore
        (c) => c.arc.uuid === selectedEntry?.uuid
      );
      //@ts-ignore
      setFilteredArcs(filtered);
      setArcAnimationCoef(0.0001);
      setTimeout(() => {
        startArcAnimation();
      }, 500);
    } else {
      setFilteredArcs([]);
    }
    //eslint-disable-next-line
  }, [selectedEntry, mapData.data]);

  const startArcAnimation = () => {
    setTimeout(() => animateArc(0), 100);
  };

  const animateArc = (coef: number) => {
    if (coef < 1) {
      coef += 0.1;
      setArcAnimationCoef(coef);
      setTimeout(() => {
        animateArc(coef);
      }, 50);
    }
  };
  let answeredProfiles: any[] = []
  let unansweredProfiles: any[] = []
  const onProfileClick = (info: any) => {
    if (info.index >= 0) {
      let profile: any | undefined = undefined
      if (info.object.answered) {
        profile = answeredProfiles[info.index];
      }
      else {
        profile = unansweredProfiles[info.index];
      }
      handleSelectEntry(profile);
    }
  };

  mapData.data.profiles.map(item => {
    if (item.answered) {
      answeredProfiles.push(item)
    }
    else {
      unansweredProfiles.push(item)
    }
  })

  const layers = useArcMapLayers(
    mapData.data.profiles,
      answeredProfiles,
      unansweredProfiles,
    filteredArcs,
    arcAnimationCoef,
    onProfileClick,
      iconSize
  );

  // const handleLeave = () => {
  //   setLeaving(true);
  //   setTimeout(() => {
  //     setSelectedEntry(null);
  //     setLeaving(false);
  //   }, 350);
  // };

  const handleMapViewChange = (view: MapView) => {
    if (mapViewFilter === view) return;
    setMapViewFilter(view);
    if (view === 'world') {
      setSelectedCountry(null);
    } else if (view === 'us') {
      setSelectedCountry("United States");
    }
  }

  const handleAnsweredStatusChange = (status: AnsweredStatus) => {
    if (answeredStatusFilter !== status) {
      setAnsweredStatusFilter(status);
      if (status === 'unanswered') {
        setArcMapHookFilters({answeredStatus: ['unanswered']});
      } else if (status === 'answered') {
        setArcMapHookFilters({answeredStatus: ['answered']});
      } else if (status === 'all') {
        setArcMapHookFilters({answeredStatus: ['all']});
      }
    }
  }

  const mapProfilesDescendingDate = mapData.data.profiles.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime());

  return (
    <ArcMapWrapper>
      <InteractiveMap
        title={title}
        loading={mapData.loading}
        layers={layers}
        renderTooltip={renderTooltip}
        canSelectCountry={false}
        selectedItem={selectedCountry}
        //@ts-ignore
        selectedCountry={selectedCountry}
        //@ts-ignore
        setSelectedCountry={setSelectedCountry}
        mapView="hex3d"
        onZoomChange={(size) => {
            setIconSize(size)
          }
        }
      ></InteractiveMap>
      <MapSidebar
        mapViewFilter={mapViewFilter}
        answeredStatusFilter={answeredStatusFilter}
        setMapViewFilter={handleMapViewChange}
        setAnsweredStatusFilter={handleAnsweredStatusChange}
        setFiltersIsOpen={setFiltersIsOpen}
        listisOpen={listIsOpen}
        isNota={isNota}
      />
      {isNota && (
        <NotaMapSidebar
          filtersIsOpen={filtersIsOpen}
          setListIsOpen={setListIsOpen}
          mapData={mapProfilesDescendingDate.slice(0, topSliceOfMapData)}
          getMoreMapData={() => {
            setTopSliceOfMapData(topSliceOfMapData + 100);
          }}
        />
      )}
      {/* <div style={{color:"white", zIndex:10000}}>TESTING THIS</div> */}

      {isNota ? (
        <>
          <MapKeyNota>
            <ul>
              <li>
                <div className={`item-color blue`} />
                <div className="map-key-item">Prayer Request</div>
              </li>
              <li>
                <div className={`item-color orange`} />
                <div className="map-key-item">Individual Praying</div>
              </li>
              <li>
                <div className={`item-color green`} />
                <div className="map-key-item">Answered prayer</div>
              </li>
            </ul>
          </MapKeyNota>
          <MapKeyNotaDecoration />
        </>
      ) : (
        <MapKey>
          <>
            <div className={`item-color blue`} />
            <div className="map-key-item">Prayer Request</div>
          </>
          <>
            <div className={`item-color orange`} />
            <div className="map-key-item">Individual Praying</div>
          </>
          <>
            <div className={`item-color green`} />
            <div className="map-key-item">Answered prayer</div>
          </>
        </MapKey>
      )}
    </ArcMapWrapper>
  );
};

export default ArcMap;
