import { iconMapping } from "../arcMapConfig";
//@ts-ignore
import { IconLayer } from "@deck.gl/layers";
import AnimatedArcLayer from "../animatedArcLayer";

const useArcMapLayers = (
  profiles: any[],
  answeredProfiles: any[],
  unansweredProfiles: any[],
  filteredArcs: any[],
  arcAnimationCoef: number,
  onProfileClick: Function,
  iconSize?: number
) => {
  const ICON_SIZE = iconSize || .7
  const unansweredProfileLayer = {
    id: "unansweredHosts",
    data: unansweredProfiles,
    pickable: true,
    billboard: false,
    iconAtlas: filteredArcs.length ? "/reporting-atlas-new-dim.png" : "/reporting-atlas-new.png",
    iconMapping,
    sizeScale: 15,
    getPosition: (d: any) => [d?.coords[1], d?.coords[0]],
    getSize: ICON_SIZE,
    getIcon: () => 0,
    onClick: onProfileClick,
    autoHighlight: true,
    highlightColor: [0, 0, 0, 64],
    loadOptions: {
      imagebitmap: {
        premultiplyAlpha: "premultiply",
      },
    },
    LayerClass: IconLayer,
    hoverTypeName: "pin",
  };
  const answeredProfileLayer = {
    id: "answeredHosts",
    data: answeredProfiles,
    pickable: true,
    billboard: false,
    iconAtlas: filteredArcs.length ? "/reporting-atlas-new-dim.png" : "/reporting-atlas-new.png",
    iconMapping,
    sizeScale: 15,
    getPosition: (d: any) => [d?.coords[1], d?.coords[0]],
    getSize: ICON_SIZE,
    getIcon: () => 2,
    onClick: onProfileClick,
    autoHighlight: true,
    highlightColor: [0, 0, 0, 64],
    loadOptions: {
      imagebitmap: {
        premultiplyAlpha: "premultiply",
      },
    },
    LayerClass: IconLayer,
    hoverTypeName: "pin",
  };
  const individualLayer = {
    id: "individual",
    data: filteredArcs,
    pickable: false,
    billboard: false,
    iconAtlas: "/reporting-atlas-new.png",
    iconMapping,
    sizeScale: 15,
    getPosition: (d: any) => [d.arc?.coords[1], d.arc?.coords[0]],
    getSize: ICON_SIZE,
    getIcon: () => 1,
    onClick: onProfileClick,
    autoHighlight: true,
    highlightColor: [0, 0, 0, 64],
    loadOptions: {
      imagebitmap: {
        premultiplyAlpha: "premultiply",
      },
    },
    LayerClass: IconLayer,
    hoverTypeName: "pin",
  };
  const singleLayer = {
    id: "single",
    data: filteredArcs,
    pickable: false,
    billboard: false,
    iconAtlas: "/reporting-atlas-new.png",
    iconMapping,
    sizeScale: 15,
    getPosition: (d: any) => [d.profile?.coords[1], d.profile?.coords[0]],
    getSize: ICON_SIZE,
    getIcon: () => 0,
    onClick: onProfileClick,
    autoHighlight: true,
    highlightColor: [0, 0, 0, 64],
    loadOptions: {
      imagebitmap: {
        premultiplyAlpha: "premultiply",
      },
    },
    LayerClass: IconLayer,
    hoverTypeName: "pin",
  };
  const arcLayer = {
    id: "arc-layer",
    pickable: true,
    data: filteredArcs,
    getWidth: () => 3,
    getSourcePosition: (d: any) => [d.arc?.coords[1], d.arc?.coords[0]],
    getTargetPosition: (d: any) => [d.profile?.coords[1], d.profile?.coords[0]],
    getSourceColor: () => [255, 255, 255, 255],
    getTargetColor: () => [85, 123, 212, 255],
    coef: arcAnimationCoef, // [0, 1]
    LayerClass: AnimatedArcLayer,
    hoverTypeName: "arc",
  };
  return [unansweredProfileLayer,answeredProfileLayer, arcLayer, individualLayer,singleLayer];
};

export default useArcMapLayers;
