export const capitalize = (str: string) => {
    const capitalized = str.charAt(0).toUpperCase() + str.slice(1);
    return capitalized;
};

export const convertTimestampToHumanReadable = (timestamp: string) => {
    let date = new Date(timestamp);
    let month = date.toLocaleString("default", { month: "long" });
    return `${month} ${date.getDate()}, ${date.getFullYear()}`;
};
