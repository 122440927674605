import "./styles/global.scss";

import React, { useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Admin from "./pages/admin/";
import PrayerWall from "./pages/prayerWall";
import EmbedForm from "./pages/embedForm";
import { ThemeProvider } from "styled-components";
import { GlobalStyles, themes } from "./theme";
import { PrayerRequestContext } from "./hooks/context/prayerRequest/prayerRequestContext";
// import ReactGA from "react-ga";

import "./i18n";

// Disabled react-ga for now, Compilation errors with the map library generate conflicts.
// GA tracking was added in index.html

// const TRACKING_ID = "G-KCVDW3QSCY";
// ReactGA.initialize(TRACKING_ID, { debug: true });

const App = () => {
  // useEffect(() => {
  //   ReactGA.pageview(window.location.pathname + window.location.search);
  // }, []);
  return (
    <ThemeProvider theme={themes.light}>
      <GlobalStyles />
      <PrayerRequestContext>
        <Router>
          <Switch>
            <Route exact path="/embed/form" component={EmbedForm} />
            <Route exact path="/admin" component={Admin} />
            {/* <Route exact path="/:influencer" render={(props) => <PrayerWall {...props} />} /> */}
            <Route path="/" render={(props) => <PrayerWall {...props} />} />
          </Switch>
        </Router>
      </PrayerRequestContext>
    </ThemeProvider>
  );
};

export default App;
