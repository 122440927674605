import styled from "styled-components";
import { FormInput } from "../../../components/requestForm/requestForm.styles";
import { AdminItem } from "../admin.styles";

export const LoginWrapper = styled(AdminItem)`
  width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${({ theme }) => theme.utilities?.spacing?.["space-md"]};

  button {
    background-color: rgb(85, 123, 212);
    color: #fff;
  }

  @media (max-width: 768px) {
    text-align: center;
    width: 90%;
  }
`;

export const LoginInputWrapper = styled.div`
  width: 100%;
  position: relative;

  .input-icon {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
  }
`;
export const LoginInput = styled(FormInput)`
  background-color: transparent;
  padding-left: 35px;
`;

export const LoginError = styled.p`
  color: #b50000;
  font-weight: bold;
  font-size: 0.85rem;
`;
