import { createContext, PropsWithChildren, useContext, useState } from "react";
import { 
  PrayerWallEntry,
  FormResponses,
  SeekerResponse,
  RequestAnswerResponse,
} from "../../../types";
import { FormValidation } from "../../useFormValidation";
import useGeolocation, { Geolocation } from "../../useGeolocation";
import useRequestForm from "../../useRequestForm";

type PrayerRequestContextResult = {
  responses: FormResponses;
  generateNewEntry: () => Promise<PrayerWallEntry>;
  validation: FormValidation;
  onInputChange: (key: string, value: any) => void;
  geolocation: Geolocation | undefined;
  submitForm: () => Promise<SeekerResponse>;
  thankYouMessage: string;
  setThankYouMessage: React.Dispatch<React.SetStateAction<string>>;
  markRequestAsAnswered: (uuid: string, description: string) => Promise<RequestAnswerResponse>;
};

const prayerRequestContext = createContext<PrayerRequestContextResult>(
  {} as any
);

export const PrayerRequestContext = (props: PropsWithChildren<{}>) => (
  <prayerRequestContext.Provider value={usePrayerRequest()}>
    {props.children}
  </prayerRequestContext.Provider>
);

export const usePrayerRequestContext = () => useContext(prayerRequestContext);

const usePrayerRequest = (): PrayerRequestContextResult => {
  const geolocation = useGeolocation();
  const [thankYouMessage, setThankYouMessage] = useState("");

  const { generateNewEntry, responses, validation, onInputChange, submitForm, markRequestAsAnswered } =
    useRequestForm(geolocation);

  return {
    responses,
    generateNewEntry,
    validation,
    onInputChange,
    geolocation,
    submitForm,
    thankYouMessage,
    setThankYouMessage,
    markRequestAsAnswered,
  };
};
