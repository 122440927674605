import { useState } from "react";

export type FormValidation = {
  name: boolean;
  // email: boolean;
  phone: boolean;
  prayer_request: boolean;
  // discipleship_status: boolean;
  [key: string]: boolean;
};

const defaultFormValidation: FormValidation = {
  name: true,
  // email: true,
  phone: true,
  prayer_request: true,
  // discipleship_status: true,
};

const useFormValidation = () => {
  const [validation, setValidation] = useState<FormValidation>(
    defaultFormValidation
  );

  const checkValidation = (key: string, ...args: any[]) => {
    if (!validators[key]) return;

    setValidation({
      ...validation,
      [key]: validators[key](...args),
    });
  };

  return {
    validation,
    checkValidation,
  };
};

const validateEmail = (email: string) => {
  return !!email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/g);
};

const validatePhone = (phone: string) => {
  if (phone.length === 0) return true;
  return !!phone.match(
    /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/g
  );
};

const validatePrayerRequest = (prayer_request: string) => {
  return prayer_request.length > 0;
};

const validateDiscipleshipStatus = (discipleship_status: string) => {
  return discipleship_status !== null;
};

const validators: { [key: string]: (...args: any[]) => boolean } = {
  // email: validateEmail,
  phone: validatePhone,
  prayer_request: validatePrayerRequest,
  // discipleship_status: validateDiscipleshipStatus,
};

export default useFormValidation;