import { NavLink, useLocation } from "react-router-dom";
import {
  AppBarWrapper,
  AppBarLogo,
  AppBarMenu,
} from "./prayerWallAppBar.styles";

import newLogo from "../../../media/new-logo.svg";
import logoHorizontal from "../../../media/logo-horizontal.svg";
import { PrayerWallEntry } from "../../../types";
// const s3 = process.env.REACT_APP_S3_URL;

type PrayerWallAppBarProps = {
  selectedEntry?: PrayerWallEntry | null;
};

const PrayerWallAppBar: React.FC<PrayerWallAppBarProps> = ({
  selectedEntry
}) => {
  const location = useLocation();
  const searchParams = location.search;
  return (
    <AppBarWrapper>
      <AppBarLogo src={logoHorizontal} alt="Prayer Map Logo"></AppBarLogo>
      <AppBarMenu>
        <ul>
          <NavLink
              to={{ pathname: "/submit-request", search: searchParams }}
              activeClassName="active"
            >
            <li>Submit Request</li>
          </NavLink>
          <NavLink
              to={
                selectedEntry
                  ? `/requests/${selectedEntry.uuid}${searchParams}`
                  : `/requests${searchParams}`
              }
          >
            <li>View Requests</li>
          </NavLink>
        </ul>
      </AppBarMenu>
    </AppBarWrapper>
  );
};

export default PrayerWallAppBar;
