import styled from "styled-components";

const sidebarWidth = "333px";

export const SidebarWrapper = styled.div`
  position: absolute;
  z-index: 5;
  height: 233px;
  top: 0;
  left: -${sidebarWidth};
  transition: 0.3s ease;
  background: #88D0FF;
  opacity: 0.95;

  &.open {
    left: 0;
    z-index: 7;
  }  
  &.listIsOpen {
    left: calc(-${sidebarWidth} - 5rem);
  }

  &::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 100%;
    background: #88D0FF;
    -webkit-transform-origin: 100% 0;
    -ms-transform-origin: 100% 0;
    transform-origin: 100% 0;
    -webkit-transform: skew(104deg);
    -ms-transform: skew(104deg);
    transform: skew(104deg);
  }

  &.isNota::after {
    background: #08516f;
  }

  @media screen and (max-width: 375px) {
    left: -311px;
  }
  @media (max-width: 1024px) and (max-height: 768px) {
    margin-top: 50px;

    &.isNota {
      margin-top: 0px;
    }
  }
  @media (max-width: 400px) and (max-height: 800px) {
    margin-top: 0px;
  }
  @media (min-width: 1025px) {
    margin-top: 50px;
    
    &.isNota {
      margin-top: 0px;
    }
  }
`;

export const SidebarToggle = styled.button`
  position: absolute;
  top: 4rem;
  width: 4rem;
  height: 4rem;
  right: -4rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  z-index: 5;
  background: #88D0FF;
  color: #fff;
  font-size: 1.9rem;

  &.isNota {
    background: #08516f;
    top: 7rem;
    margin-top: 0;
  }
`;

export const Sidebar = styled.div`
  width: ${sidebarWidth};
  height: 100%;
  padding-top: 1rem;
  background-color: ${({ theme }) => theme?.colors["ui-gray-lighter"]};
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 5;
  display: flex;
  flex-direction: column;

  > * {
    transition: 0.3s ease;
    &.seeker-selected {
      transform: translateX(-100%);
    }
  }

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    border-radius: ${({ theme }) =>
      theme?.utilities.borders["border-radius-md"]};
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    background: ${({ theme }) => theme?.colors?.["ui-gray-dark"]};
    opacity: 0.4;
    box-shadow: 0 0 2px 4px rgba(0, 0, 0, 0) inset;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: ${({ theme }) =>
      theme?.utilities.borders["border-radius-md"]};
    background: ${({ theme }) => theme?.colors?.["scrollbar"]};

    &:hover {
      background: ${({ theme }) => theme?.colors?.["table-line"]};
    }
  }

  &.isNota {
    background-color: #08516f;
  }

  @media screen and (max-width: 375px) {
    width: 311px;
  }
`;

export const NotaSidebarWrapper = styled.div`
  position: absolute;
  z-index: 6;
  height: 100svh;
  top: 0;
  right: -100%;
  transition: right 0.3s ease;
  background: rgba(0, 0, 0, 0.8);
  opacity: 0.95;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 0 0 0;
  height: 100%;

  &.open {
    right: 0;
  }
  &.filtersIsOpen {
    right: calc(-100% - 4rem);
  }

  @media screen and (max-width: 360px) {
    /* right: -333px; */
  }
  @media (max-width: 1024px) and (max-height: 768px) {
    /* margin-top: 50px; */
  }
  @media (max-width: 400px) and (max-height: 800px) {
    /* margin-top: 0px; */
  }
  @media (min-width: 1025px) {
    /* margin-top: 50px; */
  }
`;

export const NotaSidebarToggle = styled.button`
  position: absolute;
  top: 7rem;
  width: 4rem;
  height: 4rem;
  left: -4rem;
  z-index: 5;
  background: #08516f;
  color: #fff;
  font-size: 1.9rem;

  &.togglerIsOpen {
    left: -1rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    z-index: 101;
  }
  &:not(.togglerIsOpen) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
`;

export const NotaSidebar = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme?.colors["ui-gray-lighter"]};
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 5;
  display: flex;
  flex-direction: column;
  transform: translateX(.2rem);
  padding-left: 2rem;
  padding-right: 2rem;

  > * {
    transition: 0.3s ease;
    &.seeker-selected {
      transform: translateX(-100%);
    }
  }

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    border-radius: ${({ theme }) =>
      theme?.utilities.borders["border-radius-md"]};
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    background: ${({ theme }) => theme?.colors?.["ui-gray-dark"]};
    opacity: 0.4;
    box-shadow: 0 0 2px 4px rgba(0, 0, 0, 0) inset;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: ${({ theme }) =>
      theme?.utilities.borders["border-radius-md"]};
    background: ${({ theme }) => theme?.colors?.["scrollbar"]};

    &:hover {
      background: ${({ theme }) => theme?.colors?.["table-line"]};
    }
  }

  @media screen and (min-width: ${({ theme }) =>
      theme?.utilities?.breakpoints?.sm}) {
    max-width: 600px;
  }

  @media screen and (min-width: ${({ theme }) =>
      theme?.utilities?.breakpoints?.lg}) {
    width: 800px;
  }
`;
