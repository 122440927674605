import styled from "styled-components";

export const Wrapper = styled.div`
  width: 90%;
  position: relative;
  background-color: #f7f7f7;
  top: calc(${({ theme }) => theme.utilities?.spacing?.["space-sm"]} * 1.5);
  bottom: calc(${({ theme }) => theme.utilities?.spacing?.["space-sm"]} * 1.5);
  right: calc(${({ theme }) => theme.utilities?.spacing?.["space-sm"]} * 1.5);
  left: calc(${({ theme }) => theme.utilities?.spacing?.["space-sm"]} * 1.5);
  padding: ${({ theme }) => theme.utilities?.spacing?.["space-md"]};
  border-radius: ${({ theme }) =>
    theme?.utilities?.borders?.["border-radius-md"]};
  display: flex;
  flex-direction: column;
  align-items: left;
`;

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 1rem 0;
`;

export const Dialogue = styled.label`
  display: flex;
  align-items: start;
  color: #1f356e;
  p {
    font-style: italic;
    font-size: 0.8rem;
  }
  img {
    width: 0.8rem;
    margin-right: 5px;
    margin-top: 5px;
  }
`;

export const Title = styled.h1`
  font-size: 2rem;
  font-weight: bold;
  color: black;
  line-height: 1.2;
`;

export const Description = styled.p`
  font-size: 1rem;
  color: black;
`;