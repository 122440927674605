import { useEffect, useState } from "react";
import ReactTooltip from "react-tooltip";
import { PrayerWallEntry } from "../../types";
import ArcMap from "../arcMap";
import {
  FullMapWrapper,
  MapContent,
  MapControllers,
  CloseFSSidebarBtn,
  ExitFullscreenBtn,
  GoFullscreenBtn,
  OpenFSSidebarBtn,
  ClearMapSelection,
} from "./styles";

import expandIcon from "../../media/expand.svg";
import circleXIcon from "../../media/circle-x-overlay.svg";
import circleChevronLeftIcon from "../../media/circle-chevron-left.svg";
import circleChevronRightIcon from "../../media/circle-chevron-right.svg";

type FullMapProps = {
  onReset: () => void;
  onSearch: (value: string) => void;
  selectedEntry: PrayerWallEntry | null;
  handleSelectEntryFromMap: (entry: PrayerWallEntry) => void;
  fullscreen: boolean;
  toggleFullscreen: (bool: boolean) => void;
  isNota: boolean;
  handleLeave: () => void;
};

const FullMap: React.FC<FullMapProps> = ({
  onReset,
  onSearch,
  selectedEntry,
  handleSelectEntryFromMap,
  fullscreen,
  toggleFullscreen,
    isNota,
    handleLeave,
}) => {
  const arcMapFilters = {
    foo: [],
  };
  const [toggleFSSidebar, setToggleFSSidebar] = useState(false);
  useEffect(() => {
    isNota && handleClickGoFS()
  },[])
  const handleClickGoFS = () => {
    toggleFullscreen(true);
    (document.querySelector('.left-wrapper') as HTMLElement).classList.add('go-left-fs');
    (document.querySelector('.right-wrapper') as HTMLElement).classList.add('go-right-fs');
    (document.querySelector('.fullmap-wrapper') as HTMLElement).classList.add('go-head-fs');
    //(document.querySelector('.footer-wrapper') as HTMLElement).classList.add('go-foot-fs');
  }
  const handleClickExitFS = () => {
    toggleFullscreen(false);
    setToggleFSSidebar(false);
    (document.querySelector('.left-wrapper') as HTMLElement).classList.remove('go-left-fs');
    (document.querySelector('.right-wrapper') as HTMLElement).classList.remove('go-right-fs');
    (document.querySelector('.fullmap-wrapper') as HTMLElement).classList.remove('go-head-fs');
    //(document.querySelector('.footer-wrapper') as HTMLElement).classList.remove('go-foot-fs');
  }
  const [isLandscape, setOrientation] = useState(window.innerHeight < window.innerWidth);
  const [likeDesktop, setLikeDesktop] = useState(false);
  const onWindowResize = () => {
    setTimeout(() => {
      setOrientation(window.innerHeight < window.innerWidth);
      window.matchMedia('(min-width: 1025px)').matches ? setLikeDesktop(true) : setLikeDesktop(false);
      (document.querySelector('.right-wrapper') as HTMLElement).style.display = 'block';
      setToggleFSSidebar(true);
      !isNota && toggleFullscreen(false);
    }, 250);
  };
  // const [showOverlay, setShowOverlay] = useState("block");
  // const handleMapOverlay = () => {
  //     localStorage.setItem('showOverlay', 'none');
  //     setShowOverlay('none');
  // };
  const rwe = document.querySelector('.right-wrapper') as HTMLElement;
  const handleClickOpenFSSidebar = () => {
    rwe.classList.remove('closed');
    setToggleFSSidebar(true);
  }
  const handleClickCloseFSSidebar = () => {
    rwe.classList.add('closed');
    setToggleFSSidebar(false);
  }
  useEffect(() => (
    onWindowResize(),
    window.addEventListener('resize', onWindowResize),
    () => window.removeEventListener('resize', onWindowResize)
  ), []);

  const resetSearch = () => {
    onSearch("");
    onReset();
  }

  return (
    <FullMapWrapper className="fullmap-wrapper">
      <MapContent>
        <ArcMap
          title=""
          loading={false}
          filters={arcMapFilters}
          selectedEntry={selectedEntry}
          handleSelectEntry={handleSelectEntryFromMap}
          isNota={isNota}
          {...{ handleLeave }}
        />
      </MapContent>
      <MapControllers>
        {!isNota && <>
          <GoFullscreenBtn data-tip="Fullscreen Map" data-for="go-desktop-fullscreen" onClick={handleClickGoFS} style={{
            display: !fullscreen && (!isLandscape || likeDesktop) ? "block" : "none",
          }}><img alt="go-full-screen" src={expandIcon} /></GoFullscreenBtn>
          <ExitFullscreenBtn data-tip="Exit Fullscreen" data-for="exit-desktop-fullscreen" onClick={handleClickExitFS} style={{
            display: fullscreen && (!isLandscape || likeDesktop) ? "block" : "none"
          }}><img alt="exit-full-screen" src={circleXIcon} /></ExitFullscreenBtn>
        </>}
        <OpenFSSidebarBtn onClick={handleClickOpenFSSidebar} style={{
          display: isLandscape && (!toggleFSSidebar && !likeDesktop) ? "block" : "none"
        }}><img alt="open-fs-sidebar" src={circleChevronLeftIcon} /></OpenFSSidebarBtn>
        <CloseFSSidebarBtn onClick={handleClickCloseFSSidebar} style={{
          display: isLandscape && (toggleFSSidebar && !likeDesktop) ? "block" : "none"
        }}><img alt="close-fs-sidebar" src={circleChevronRightIcon} /></CloseFSSidebarBtn>
        {/* <ClearMapSelection data-tip="Clear map selection" data-for="clear-map-selection" onClick={resetSearch} style={{
          display: selectedEntry && (likeDesktop || !isLandscape) ? "block" : "none"
        }}><img alt="close-fs-sidebar" src={circleXIcon} /></ClearMapSelection> */}
        {likeDesktop && <ReactTooltip id="go-desktop-fullscreen" place="left" type="light" effect="solid" />}
        {likeDesktop && <ReactTooltip id="exit-desktop-fullscreen" place="left" type="light" effect="solid" />}
        {likeDesktop && <ReactTooltip id="clear-map-selection" place="left" type="light" effect="solid" />}
      </MapControllers>
      {/* <MapOverlay style={{ display: showOverlay }}>
        <div className="overlay-content">
          <h2>How Can We Pray For You Today?</h2>
          <p>
            You may add your prayer request to our prayer wall using the
            form to the right. Once your prayer request is received, we
            will share it according to your instructions. Feel free to
            submit as many prayer requests as you'd like!
          </p>
          <Button title="Got it" onClick={handleMapOverlay}>
            <FontAwesomeIcon icon={faCheck} />
          </Button>
        </div>
      </MapOverlay> */}
    </FullMapWrapper>
  )
}

export default FullMap;
