import {
  PrayerWallEntry,
  SeekerResponse,
  RequestAnswerResponse,
} from "../types";
import getApiUrl from "../utils/getApiUrl";
import makeRequest from "./make-request";

const API_BASE = getApiUrl(
  process.env.REACT_APP_UL_SERVER_BASE,
  "http://localhost:80/api/v1",
  true
);

const requestURL = getApiUrl(
  process.env.REACT_APP_API_URL,
  "http://localhost:94/api",
  true
);

const retrieveRecentEntries = async (
  search: string,
  offset?: number
): Promise<PrayerWallEntry[]> => {
  return makeRequest(requestURL, "/recent", "get", {
    offset,
    search,
  }) as unknown as PrayerWallEntry[];
};

type IncrementPrayedForCountResult = { success: boolean };
const incrementPrayedForCount = async (
  uuid: string,
  coords?: number[]
): Promise<IncrementPrayedForCountResult> => {
  return makeRequest(requestURL, `/${uuid}/pray`, "post", {
    coords,
  }) as unknown as IncrementPrayedForCountResult;
};

const createPrayerWallEntry = async (entry: any) => {
  return makeRequest(
    requestURL,
    "/",
    "post",
    entry
  ) as unknown as PrayerWallEntry;
};

const updatePrayerWallEntry = async (uuid: any, entryDto: any) => {
  return makeRequest(
    requestURL,
    `/${uuid}`,
    "put",
    entryDto
  ) as unknown as PrayerWallEntry;
};

const submitSeeker = async (formSubmition: any) => {
  return makeRequest(
    API_BASE,
    "/seeker-portal/submit-form",
    "post",
    formSubmition
  ) as unknown as SeekerResponse;
};

const updateSeeker = async (email: string = "", updateObj: unknown = {}) => {
  return makeRequest(
    API_BASE,
    "/seeker-portal/seeker/" + email,
    "put",
    updateObj
  ) as unknown as SeekerResponse;
};

const getEncouragementEmails = async (): Promise<string[]> => {
  return makeRequest(
    requestURL,
    "/weekly-encouragement",
    "get",
    {}
  ) as unknown as any[];
};

const getPendingRequests = async (): Promise<PrayerWallEntry[]> => {
  return makeRequest(
    requestURL,
    "/pending",
    "get",
    {}
  ) as unknown as PrayerWallEntry[];
};

const processRequest = async (
  uuid: string,
  approved: boolean
): Promise<any> => {
  return makeRequest(requestURL, `/${uuid}/approve`, "put", { approved });
};

const approveAllRequests = async () => {
  return makeRequest(requestURL, `/approve-all`, "get");
};

const markPrayerRequestAsAnswered = async (
  uuid: string,
  description: string = ""
) => {
  return makeRequest(requestURL, `/${uuid}/answer`, "put", {
    description,
  }) as unknown as RequestAnswerResponse;
};

const getArcData = async (
  dateRange: string,
  type: string,
  partner: string,
  filters = {}
) => {
  return makeRequest(requestURL, "/arcs", "post", {
    dateRange,
    type,
    partner,
    filters,
  });
};

const serverClient = {
  retrieveRecentEntries,
  incrementPrayedForCount,
  createPrayerWallEntry,
  updatePrayerWallEntry,
  getEncouragementEmails,
  getPendingRequests,
  processRequest,
  approveAllRequests,
  getArcData,
  submitSeeker,
  updateSeeker,
  markPrayerRequestAsAnswered,
};

export default serverClient;
