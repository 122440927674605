import { CSVLink } from "react-csv";
import styled from "styled-components";
import { AdminItem } from "../admin.styles";

export const EncouragementListWrapper = styled(AdminItem)`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: auto auto;
  grid-template-areas:
    "title download"
    "total download";
  align-items: center;
  gap: ${({ theme }) => theme.utilities?.spacing?.["space-xs"]};

  .loader-wrapper {
    margin-top: ${({ theme }) => theme.utilities?.spacing?.["space-sm"]};
    width: fit-content;
    transform: scale(0.5);
    grid-area: total;
  }

  @media (max-width: 768px) {
    width: 90%;
    text-align: center;

    display: flex;
    flex-direction: column;
    align-items: center;
    .loader-wrapper {
      margin: ${({ theme }) => theme.utilities?.spacing?.["space-sm"]} 0;
    }
  }
`;

export const EncouragementListTitle = styled.p`
  font-size: 1.15rem;
  grid-area: title;
`;

export const EncouragementListTotal = styled.h2`
  grid-area: total;
`;

export const DownloadButton = styled(CSVLink)`
  grid-area: download;
  background-color: #557bd4;
  padding: ${({ theme }) => theme.utilities?.spacing?.["space-xs"]}
    ${({ theme }) => theme.utilities?.spacing?.["space-sm"]};
  text-transform: uppercase;
  letter-spacing: 0.08em;
  cursor: pointer;
  color: white !important;
  border-style: none;
  width: fit-content;
  border-radius: 0.25rem;
  font-weight: 700;
  font-size: 0.85rem;
  transition: 0.2s;
  text-align: center;

  &:hover {
    filter: brightness(0.8);
  }
`;
