import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faSlidersH } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import MapSidebarFilters from "./mapSidebarFilters";
import { AnsweredStatus, MapView } from "./mapSidebarFilters/mapSidebarFilters";
import { Sidebar, SidebarToggle, SidebarWrapper } from "./styles";

type MapSidebarProps =  {
  mapViewFilter: MapView;
  answeredStatusFilter: AnsweredStatus;
  setMapViewFilter: (view: MapView) => void;
  setAnsweredStatusFilter: (status: AnsweredStatus) => void;
  setFiltersIsOpen: (isOpen: boolean) => void;
  listisOpen: boolean;
  isNota: boolean;
};

const MapSidebar: React.FC<MapSidebarProps> = ({
  mapViewFilter,
  answeredStatusFilter,
  setMapViewFilter,
  setAnsweredStatusFilter,
  setFiltersIsOpen,
  listisOpen,
  isNota,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    setFiltersIsOpen(isOpen);
  }, [isOpen]);

  return (
    <SidebarWrapper
      className={`${isOpen && "open"} ${listisOpen && "listIsOpen"} ${
        isNota && "isNota"
      }`}
    >
      <SidebarToggle
        className={`btn toggle ${isNota && "isNota"}`}
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        <FontAwesomeIcon icon={faSlidersH as IconProp} />
      </SidebarToggle>
      <Sidebar className={`${isNota && "isNota"}`}>
        <MapSidebarFilters
          isNota={isNota}
          mapView={mapViewFilter}
          answeredStatus={answeredStatusFilter}
          onMapViewChange={setMapViewFilter}
          onAnsweredStatusChange={setAnsweredStatusFilter}
        />
      </Sidebar>
    </SidebarWrapper>
  );
};

export default MapSidebar;
